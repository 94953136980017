import React from 'react'
import { Col, Row } from 'reactstrap'

const WhyUs = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding8rem1 py-lg-5 py-md-2 py-4'>
            <Row className='py-md-3'>
                <Col xs='11' lg='5' className=''>
                    <div className=''>
                        <h3 className='fs-ss-40 text-philos fw-700'>
                            Why Us?
                        </h3>
                    </div>
                    <div className='py-md-3'>
                        <h3 className='fs-ss-24 text-mulish fw-500'>
                            We Are Restless Explorers
                        </h3>
                    </div>
                    <div className='pt-2 me-md-4'>
                        <p className='fs-ss-18 text-mulish fw-500 lh-1-2'>
                        At<strong> ZM Communication,</strong> as a digital marketing agency in Canada, We strive to find the pattern between your customers, your brand, and your company's performance because we are seekers of perfection.
                        </p>
                    </div>
                    <div className='pt-2 me-md-4'>
                        <p className='fs-ss-18 text-mulish fw-500 lh-1-2'>
                        <strong>We Are the Wavemakers:</strong> We make things happen for you by creating the momentum of success through achieving your objectives.
                        </p>
                    </div>
                    <div className='pt-2 me-md-4'>
                        <p className='fs-ss-18 text-mulish fw-500 lh-1-2'>
                        <strong>We Are the Sparking Innovators:</strong> We increase your company's ROI through tailored strategies and scroll-stopping content.  
                      </p>
                    </div>
                    <div className='pt-2 me-md-4'>
                        <p className='fs-ss-18 text-mulish fw-500 lh-1-2'>
                        <strong>We Are Groundbreakers:</strong> We have the ideal team of diverse and bold trendsetting experts who prioritize their clients' success and business growth.
                        </p>
                    </div>
                </Col>
                <Col xs='11' lg='5' className=''>
                    <div className='me-md-3'>
                        <img src='home/Why-Us-image.png' alt='Why Z.M Communication' className='img-h-3' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default WhyUs
import React from 'react'

const BannerMarketing = () => {
  return (
    <div id='website-responsive'>
    <div className='bg-img1 padding-marketing' style={{ backgroundImage: "url('marketing/ZM-Mississauga-page-banner.jpg')", width: '100%', backgroundColor: 'rgba(0,0,0, 0.60)' }}>
    <div className=''>
        <h3 className='fs-ss-24 text-mulish fw-500 text-white' style={{ letterSpacing:'1px'}}>
            It’s All About Making Ideas Happen
        </h3>
    </div>

    <div className=''>
        <h1 className='fw-700 fs-ss-44 text-ss-primary text-philos'>
            Best Digital Marketing Agency in Mississauga with Real Returns
        </h1>
    </div>
    </div>
</div>
  )
}

export default BannerMarketing
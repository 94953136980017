import React from 'react'
import { Col, Row } from 'reactstrap'

const HealtcareITSolutions = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding5rem1 rightpadding8rem1 py-lg-5 py-4'>
            <Row className=''>
                <Col lg='6' className='mt-lg-5 mt-4 mb-lg-0 mb-4'>
                    <div className=''>
                        <h3 className='fs-ss-36 text-philos fw-800 text-ss-black lh-1-4'>
                            Healthcare IT Solutions<br/> for Doctors, Clinics and Hospitals
                        </h3>
                    </div>

                    <div className='mt-lg-4'>
                        <p className='fs-ss-16 text-mulish fw-500 text-ss-black '>
                        We use groundbreaking technology to get to the places that matter the most. Furthermore, we guarantee to make the most cost-effective, efficient, and professional healthcare solution service accessible. We will continue collaborating with you to provide the most effective and up-to-date services for your practice, such as.
                        </p>
                        <p className='fs-ss-16 text-mulish fw-500 text-ss-black'>
                            <ul className='fs-ss-16 text-mulish fw-500 text-ss-black'>
                                <li className='mb-1'>Online Scheduling and Appointments for Your Patients</li>
                                <li className='mb-1'>HIPAA Compliant Digital Forms</li>
                                <li className='mb-1'>Digital Marketing Services That Cater to the Healthcare Industry </li>
                                <li className='mb-1'>Designing and Branding Medical Websites</li>
                            </ul>
                        </p>
                    </div>
                </Col>
                <Col lg='6' className=''>
                    <div className='pe-md-0 pe-3'>
                        <img src='/healthcare/Health-Care-Image-2.jpg' alt='' className='img-home-1' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default HealtcareITSolutions
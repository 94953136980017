import React,{useEffect} from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

function validateEmail(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }
    return error;
}

function validateUsername(value) {
    let error;
    if (value === 'admin') {
        error = 'Nice try!';
    }
    return error;
}

const PackagesForm = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    return(
        <>
            <div id='website-responsive' style={{ overflow:'hidden', backgroundImage:'url("bg-img/MicrosoftTeams-image.png")', width:'100%', position:'', backgroundAttachment:'fixed'}}>
    
    <div className='left-padding10rem1 right-padding10rem1 py-lg-5 py-md-4 py-4'>

    <div className='mb-lg-5 mb-4'>
    <center>
        <h2 className='fs-ss-40 text-mulish fw-800'>
            Your Information
        </h2>
        <hr className='hr-style' style={{ width:'15%'}}/>

    </center>
    </div>
        <form>
            <Row className='d-flex justify-content-center'>
                <Col lg='6' md='6' sm='12' xs='12' className='mb-lg-4 mb-3'>
                    <div className=''>
                        <input type='' required name='fname' className='p-2' placeholder='First Name' style={{ width: '100%' }} />
                    </div>
                </Col>
                <Col lg='6' md='6' sm='12' xs='12' className='mb-lg-4 mb-3'>
                    <div className=''>
                        <input type='' required name='lname' className='p-2' placeholder='Last Name' style={{ width: '100%' }} />
                    </div>
                </Col>
                <Col lg='6' md='6' sm='12' xs='12' className='mb-lg-4 mb-3'>
                    <div className=''>
                        <input type='email' required name='email' className='p-2' placeholder='Email' style={{ width: '100%' }} />
                    </div>
                </Col>
                <Col lg='6' md='6' sm='12' xs='12' className='mb-lg-4 mb-3'>
                    <div className=''>
                        <input type='number' required name='pnumber' className='p-2' placeholder='Phone Number' style={{ width: '100%' }} />
                    </div>
                </Col>
            </Row>

            <div className=''>
                <label for='' className='fs-ss-18 text-philos fw-600'>Packages</label>
            </div>
            <div className=''>
                <select name="cars" id="cars" className='p-2' style={{ width: '100%' }}>
                    <option value="logo-design">Logo Design</option>
                    <option value="branding">Branding</option>
                    <option value="opel">Opel</option>
                    <option value="audi">Audi</option>
                </select>
            </div>

            <div className='mt-3'>
                <label for='' className='fs-ss-18 text-philos fw-600'>Please tell us briefly about your business or the services
                </label>
            </div>
            <div className='mt-2'>
               <textarea className='p-2' rows={5} placeholder='Business Description' style={{ width:'100%' }}/>
            </div>
            
            <div className='mt-3'>
                <label for='' className='fs-ss-18 text-philos fw-600'>Anything additional that you want to add in the package
                </label>
            </div>
            <div className='mt-2'>
               <textarea className='p-2' rows={5} placeholder='Additional Comments' style={{ width:'100%' }}/>
            </div>

            <div className=''>
                <input type='checkbox' className='me-2' />
                <label className='fs-ss-18 text-philos fw-500 '>
                    I have read and agree to the<Link to='' className='text-ss-primary' style={{ textDecoration:'none'}}> Terms & Conditions.</Link>
                </label>
            </div>

            <div className='d-flex justify-content-center mt-4'>
                <Button type='submit' className='btn-ss-primary'>
                    Submit
                </Button>
            </div>
        </form>
    </div>
</div>
        </>
    )};

export default PackagesForm;
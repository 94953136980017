import React from 'react'
import { TypeAnimation } from 'react-type-animation'

const HomeMain = () => {
  return (
    <div id='website-responsive'>
    <div className='bg-img padding-main' style={{ backgroundImage:"url('/home/Header-image-2.png')", height:'', width:'100%', }}>
        <div className=''>
            <h3 className='fs-ss-34 text-mulish text-white fw-500' style={{ letterSpacing:'1px'}}>
                Experience The New Definition Of Success
            </h3>
        </div>
        <div className='border-start border-5 mt-4'>
            {/* <span className='fs-ss-70 text-ss-primary text-philos'> */}
                <TypeAnimation sequence={['Healthcare Solutions',1000,'Branding',1000,'Graphic Designing',1000,'Content Writing',1000,'App Development',1000,'Search Engine Optimization',1000,'Social Media Marketing',1000,'Video Animation',1000,'PPC Marketing']}
                wrapper="span"
                speed={1}
                deletionSpeed={1}
                className='fs-ss-70 textss-primary fw-800 text-philos ms-2 ms-md-4'
                style={{color:'#FE9B1F' }}
                repeat={Infinity}
                cursor={false} />
           
        </div>
    </div>
    </div>
  )
}

export default HomeMain
import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import BannerImg from '../../assets/images/bannners/FAQs.png'

const MainBanner = () => {
  return (
    <div id='website-responsive'>
    <div className='bg-img padding-faq' style={{ backgroundImage: `url(${BannerImg})` , height:'', width:'100%', }}>
        <div className=''>
            <h3 className='fs-ss-24 text-mulish text-white fw-500' style={{ letterSpacing:'1px'}}>
                Get Digital Solutions Online
            </h3>
        </div>
        <div className=' mt2'>
            {/* <span className='fs-ss-70 text-ss-primary text-philos'> */}
                <TypeAnimation sequence={['FAQs',1000,'',200]}
                wrapper="span"
                speed={1}
                deletionSpeed={0}
                className='fs-ss-60 textss-primary fw-800 text-philos ms-2 ms-md4'
                style={{color:'#FE9B1F' }}
                repeat={Infinity}
                cursor={false} />
           
        </div>
    </div>
    </div>
  )
}

export default MainBanner
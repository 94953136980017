import React, { useEffect } from 'react'
import Header2 from '../../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ServicesBanner from '../../components/haseeb/ServicesBanner'
import BannerImg from '../../assets/images/bannners/ContentWriting.png'
import Img1 from '../../assets/images/content-writing/Img1.jpg'
import Img2 from '../../assets/images/content-writing/Img2.jpg'
import { Link } from 'react-router-dom'
import { ChevronRight } from 'react-bootstrap-icons'
import MiddleBannerSecondary from '../../components/haseeb/MiddleBannerSecondary'
import MiddleBannerPrimary from '../../components/haseeb/MiddleBannerPrimary'
import { Col, Row } from 'reactstrap'
import { FaDotCircle } from 'react-icons/fa'

const ContentWriting = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <ServicesBanner
    BannerImg={BannerImg}
    SubTitle="Creating The Buzz For You"
    Title="Content Writing"
    />
<section id='website-responsive' className="container-fluid px-0">
<div id='website-responsive'>
        <div className='right-padding8rem1 ps-lg-5 ps-4 py-lg-5 py-4'>
            <Row className=''>
                <Col lg='3' className=''>
                    <div className='pb-md-3 pb-lg-0 pb-3'>
                        <div className='d-flex align-items-center'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/healthcare-solutions' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Healthcare Solutions
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/branding/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Branding
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/graphic-designing' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Graphic Designing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/content-writing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-24 text-mulish fw-500 text-ss-primary'>
                                    Content Writing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/web-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Web Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/app-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    App Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/search-engine-optimization/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Search Engine Optimization
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/social-media-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Social Media Marketing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/video-animation/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Video Animation
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/ppc-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                PPC Marketing
                            </span>
                                </Link>
                        </div>
                    </div>
                </Col>

                <Col className='ps-lg-4'>
                    <div className=''>
                        <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Professional Writers for Exceptional SEO Content Creation
                            </h2>
                        </div>

                        <div className='me-lg-5'>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            A solid online presence is super important in today's fast digital world. Creating content that people and search engines like can take time and effort. This is where <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication </Link> comes to the rescue – we're here to help you out! Our unique solution combines creating great stuff to read with getting noticed by search engines. At ZM Communication, we've got a team of really talented writers. They're great at making content that people enjoy reading, and they also know a lot about how search engines work. We promise to simplify things for you so you can do well online. Imagine you had superheroes who could create exciting content people love and make it look good on the internet. Well, that's our incredible team of writers! Whether you're reading something fun online or trying to share your message, we're here to help. ZM Communication ensures our <Link className='text-decoration-none text-ss-primary' to="/services/search-engine-optimization"> SEO </Link> writing services are enjoyable and easy to find, removing the confusion from the digital world so you can shine and succeed.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
    
    <section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-start gy-4'>
        <div className="col-11 col-md-4">
          <img className='w-100' src={Img1} alt="" />
        </div>
        <div className="col-11 col-md-6">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Types of Content Writing Services We are Offering
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             We understand that useful content should be helpful. It should give people new and essential information. We combine our content writing services and skills with our knowledge about how search engines work to ensure your content teaches, entertains, and shows up nicely when people search. This means your content will be <Link className='text-decoration-none text-ss-primary' to="/services/graphic-designing"> unique, graphically appealing,  </Link> and easy to find, even among all the other things online. With ZM Communication, you don't have to worry about making your content great and getting noticed – we'll take care of it while offering writing SEO content in Vancouver!
             
             Creating content that’s both awesome and liked by search engines can be challenging. But with ZM Communication, you don’t need to worry anymore. We are good at both things and will ensure your content stands out online. We’ll help you succeed in the digital world, so your message reaches the right people and gets the attention it deserves. In short, if you are looking for social media content writing services or for a law firm, ZM Communication is like a guide in the big online world. We provide healthcare, e-commerce, and real estate content writing services in Vancouver. With us, you can confidently explore the online world and do well. 
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerPrimary
    Title="Your Story, Our Words"
    Para="Every company's ultimate objective is to provide meaningful, top-notch content tailored to its audience's demands. However, these efforts would be futile if the content does not elicit audience behaviors that eventually assist your organization in meeting its commercial objectives. Audiences, for example, demand a piece of up-to-date information for the supplied brand because they value authenticity and dependability."
    />
    
    <section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-center gy-4'>
        <div className="col-11 col-md-5">
          <img className='w-100' src={Img2} alt="" />
        </div>
        <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             SEO-Friendly Content
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             We ensure that our customer receives their work ahead of time to offer us feedback and make the necessary changes on time. We employ our SEO-based content writing services to find the most relevant high-volume keywords, compile a list, and intelligently integrate them into the article. Thus, in addition, targeted keywords for website content, blog, social media captions, or even the CEO’s autobiography might affect public perception if managed carefully.
             Everything, from the headlines to the primary content, is strategized, planned, implemented, and changed as needed. We realize the significance of error-free content in avoiding the impact of a misaligned audience, and misperceived brand purpose has fatal consequences.         
             </p>
        </div>
        </div>
      </div>
    </section> 

    <MiddleBannerSecondary
    Title="Why Should You Choose Us?"
    Para={<>With unique, appealing, and high-quality content you can boost the effectiveness of your marketing initiatives. On our content marketing platform, you can communicate with the most diversified pool of writers. Moreover, you can enhance the content, boost productivity, and measure outcomes. Words can develop trust and integrity, while designs may stir hearts; our professional and knowledgeable writers can create unique content from scratch for a variety of objectives. Thus, our content writing services in Canada provide professionally produced material that has been thoroughly researched, optimized for search, and designed with conversion rate optimization (CRO) in mind, so that website visits convert into customers.
    <div className='pt-4'>
    <Link to="/packages" className="text-decoration-none text-white fs-ss-18 fw-500 border-2 border-white border py-2 px-3"> View Packages <ChevronRight className='fs-ss-22'/> </Link>
    </div>
    </>}
    />
    
    <section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             <span className='text-ss-primary'> 100% </span> Original SEO Content
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Every website or product description starts with 100 percent unique content that acts as a road map to your target, assisting you in growing your business. In addition, you can rapidly access our top-notch content writing services in Canada for your businesses since our professional writers are accessible for every industry. Even though most content marketing packages consist only of keyword stuffing and copy-paste text to fit the requirements, such content has no influence –people see, discard, and move! Our objective is to supply you with engaging material free of errors with correct keyword usage to help you grow your business and guide you to thriving prospects. <br/><br/>
             
             When you engage with ZM Communication, the digital marketing agency in Canada, you’ll be assigned a specialized SEO content writer who will learn about your company, industry, and target audience. Your writer will collaborate closely with the digital marketing strategist and project manager to ensure that each piece of content we offer is of outstanding quality and will provide the results that you require.
             </p>
        </div>
        </div>
      </div>
    </section>
</section>
    </>
  )
}

export default ContentWriting
import React, {useEffect, useState} from 'react';
import FsLightbox from "fslightbox-react";
import PlayIcon from '../../assets/images/videostab/play-icon.png'
import Img1 from '../../assets/images/videostab/Img1.webp'
import Img2 from '../../assets/images/videostab/Img2.webp'
import Img3 from '../../assets/images/videostab/Img3.webp'
import Img4 from '../../assets/images/videostab/Img4.webp'
import Img5 from '../../assets/images/videostab/Img5.webp'
import Img6 from '../../assets/images/videostab/Img6.webp'
import Img7 from '../../assets/images/videostab/Img7.webp'
import Img8 from '../../assets/images/videostab/Img8.webp'
import Img9 from '../../assets/images/videostab/Img9.webp'
import Img10 from '../../assets/images/videostab/Img10.webp'
import Img11 from '../../assets/images/videostab/Img11.webp'
import Img12 from '../../assets/images/videostab/Img12.webp'


const Videos = () => {
    const [toggler, setToggler] = useState(false);
    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 1
    });
    
    function openLightboxOnSlide(number) {
      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number
      });
    }
    const websiteImg = [
      {
        id: 1,
        img: Img1
    },
          {
        id: 2,
        img: Img2
    },
          {
        id: 3,
        img: Img3
    },
          {
        id: 4,
        img: Img4
    },
          {
        id: 5,
        img: Img5
    },
          {
        id: 6,
        img: Img6
    },
          {
        id: 7,
        img: Img7
    },
          {
        id: 8,
        img: Img8
    },
          {
        id: 9,
        img: Img9
    },
          {
        id: 10,
        img: Img10
    },
          {
        id: 11,
        img: Img11
    },
          {
        id: 12,
        img: Img12
    },
    
        
    ]

  return (
    <>
    <div id='website-responsive'>
        <div className='py-4 px-md-4'>
            <div className='row justify-content-center'>
            
            {websiteImg.map((webimg,index) => (
                <div key={index} className='mb-4 w-30'>
                    <div className='hover-img container-abs' onClick={() => openLightboxOnSlide(webimg.id)}>
                        <img src={`${webimg.img}`} alt='dedw' className='w-100' style={{ position:'', display:'block'}} /> 
                        <div className='overlay' style={{ position:''}}>
                          <img className='' src={PlayIcon} alt="" style={{width:"70px"}}/>
                        </div> 
                    </div>
                    
                </div>
            ))}
            </div>
        </div>
    </div>
    <FsLightbox
				toggler={lightboxController.toggler}
				sources={[
          "https://youtu.be/RAuqGFbz4iM",
          "https://www.youtube.com/watch?v=eOF0jfE9P74",
          "https://www.youtube.com/watch?v=sq305_w0fa8",
          "https://www.youtube.com/watch?v=8loAz6KIMpI",
          "https://www.youtube.com/watch?v=vdEoVrddopk",
          "https://www.youtube.com/watch?v=dQjuSYE1Q5A",
          "https://www.youtube.com/watch?v=eBaVy3pVuxA",
          "https://www.youtube.com/watch?v=QQFOqqPm4ZA",
          "https://www.facebook.com/valleypediatrics/videos/365099049032482/",
          "https://www.facebook.com/simplesolutionzcorp/videos/333071372337237/",
          "https://www.youtube.com/watch?v=xq2kCGgrhuw",
          "https://www.facebook.com/watch/?v=717296099338417",
				]}
				slide={lightboxController.slide}
			/>
    </>
  )
}

export default Videos
import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import HomeMain from '../components/home/HomeMain'
import CampaignsServices from '../components/home/CampaignsServices'
import CommunityCommitment from '../components/home/CommunityCommitment'
import OurApproach from '../components/home/OurApproach'
import WhyUs from '../components/home/WhyUs'
import OurClients from '../components/home/OurClients'
import Testimonials from '../components/home/Testimonials'

const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
        <>
        <div style={{ overflow: 'hidden', backgroundImage:'url("bg-img/MicrosoftTeams-image.png")', width:'100%', backgroundAttachment:'fixed' }}>

            <HelmetProvider>
                <Helmet prioritizeSeoTags>
                    <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
                    <meta name="description" content="With technology at the core, Z.M. Communication, digital marketing agency in Canada, to produce transformative ideas to grow businesses." />
                    <link rel="canonical" href="https://zmcommunication.org/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Digital Marketing Agency in Canada - Z.M.Communication Inc." />
                    <meta property="og:description" content="With technology at the core, Z.M. Communication, digital marketing agency in Canada, to produce transformative ideas to grow businesses." />
                    <meta property="og:url" content="https://zmcommunication.org/" />
                    <meta property="og:site_name" content="Z.M.Communication Inc." />
                    <meta property="article:publisher" content="https://www.facebook.com/zmcomm" />
                    <meta property="article:modified_time" content="2023-09-27T04:14:24+00:00" />
                    <meta property="og:image" content="https://zmcommunication.org/wp-content/uploads/2021/11/What-we-do.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@ZMCOMM" />
                </Helmet>
            </HelmetProvider>
            <Header2/>
            <HomeMain />
            <CampaignsServices />
            <CommunityCommitment />
            <OurClients/>
            <OurApproach />
            <Testimonials/>
            <WhyUs />
        </div>
        </>
    )
}

export default HomePage
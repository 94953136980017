import React from 'react'
import { Col, Row } from 'reactstrap'

const CoreValues = () => {
    return (
        <div id='website-responsive'>
            <div className='bg-img left-padding10rem1 right-padding10rem1 py-lg-5 py-4' style={{ backgroundColor: '#FE9B1F', width: '100%' }}>


                <div className=''>
                    <Row className=''>

                        <Col md='12' lg='4' className='px-5  display-block-767'>
                            <div className='px-sm-5 py-4'>
                                <img src='about/Core-values-1.png' alt='' className='' width='100%' />
                            </div>
                        </Col>

                        <Col md='12' lg='4' className='mt-3 mt-lg-5'>
                            <div className='' style={{ color: '#FE9B1F' }}>
                                <h3 className='fs-ss-30 fw-700 text-ss-black text-philos' >
                                    Passion and Excellency
                                </h3>
                            </div>
                            <div className='fs-ss-16 text-mulish text-white fw-500 lh-1-4 '>
                                We love digital marketing and are always up
                                for a new challenge since we appreciate
                                what we do. We deliver on our
                                commitments and go above and
                                beyond to give unexpected
                                value to our customers.                        </div>
                            <div className='mt-3' style={{ borderTop: '2px solid white', width: '55%' }} />
                        </Col>

                        <Col md='12' lg='4' className='display-none-767'>
                            <div className='px-md-5 px-lg-0 py-md-4 py-0'>
                                <img src='about/Core-values-1.png' alt='' className='' width='100%' />
                            </div>
                        </Col>

                        <Col md='12' lg='4' className='mt-3 mt-md-5'>
                            <div className='' style={{ color: '#FE9B1F' }}>
                                <h3 className='fs-ss-30 fw-700 text-ss-black text-philos' >
                                    Curiosity and Hard work

                                </h3>
                            </div>
                            <div className='fs-ss-16 text-mulish text-white fw-500 lh-1-4 '>
                                Our dedication and insatiable curiosity fuel
                                our marketing efforts and keep us on the
                                cutting edge of emerging technology.
                                We go deep to discover the "how"
                                and "why" by eliminating the
                                "what" and the "when".                        </div>
                            <div className='mt-3' style={{ borderTop: '2px solid white', width: '55%' }} /></Col>

                        <Col md='12' lg='3' className=''></Col>

                        <Col md='12' lg='6' className='ms-lg-4 pt-lg-4 pt-4 '>
                            <div className='d-sm-grid d-lg-grid justify-content-lg-center justify-content-md-start'>
                                <div className='d-sm-grid d-md-grid justify-content-lg-center justify-content-sm-start' style={{ color: '#FE9B1F' }}>
                                    <h3 className='fs-ss-30 fw-700 text-ss-black text-philos' style={{ color: '#FE9B1F' }}>
                                        Innovation and Optimization
                                    </h3>
                                </div>
                                <div className='d-sm-grid d-md-grid text-align-center px-lg-5'>
                                <p className='fs-ss-16 text-mulish text-white fw-500 lh-1-4 '>
                                    Everyone's focus is on the end product's success. As an
                                    online platform, we place a high value on personal accountability and the ability to plan
                                    and get good results.
                                    {/* <center> */}
                                    {/* </center> */}
                                    <div className='mt-3 ms-lg-5 d-sm-grid d-md-grid text-align-center display-block1' style={{ borderTop: '2px solid white', width: '55%' }} />
                                </p>
                                </div>
                            </div>
                        </Col>

                        <Col md='12' lg='3' className=''></Col>

                    </Row>
                </div>
            </div>
        </div>
    )
}

export default CoreValues
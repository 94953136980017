import React from 'react'
import { CheckLg } from 'react-bootstrap-icons'
import { FaCheck } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

const Packages = () => {

    const pkg = [
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            title1: 'Branding',
            value: '269',
            value1: '669',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: ' Dedicated Designers'
                },
                {
                    description1: '4 Revisions'
                },
                {
                    description1: 'Logo animation'
                },
                {
                    description1: 'Deliver in 24 Hours'
                },
                {
                    description1: 'Final Files (.AI, .PSD, .EPS, .JPEG, .PNG, .PDF)'
                },
                {
                    description1: '100% Unique Custom Concepts'
                },
                {
                    description1: '100% Satisfaction Guaranteed'
                },
            ],
            desc2: [
                {
                    description2: '4 Custom Logo Design concepts'
                },
                {
                    description2: 'Dedicated Designers'
                },
                {
                    description2: '1 Stationary Design Set'
                },
                {
                    description2: 'Flyer'
                },
                {
                    description2: '3 Pages Website'
                },
                {
                    description2: '6 Copyright Images'
                },
                {
                    description2: '3 Social Media Pages Creation'
                },
                {
                    description2: '4 Banner Designs'
                },
                {
                    description2: 'Deliver in 2 Weeks'
                },
                {
                    description2: '100% Satisfaction Guarantee'
                },
            ],
        },
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            value: '269',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
            ],
            desc2: [
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
            ]
        },
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            value: '269',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
            ],
            desc2: [
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
            ]
        },
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            value: '269',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
            ],
            desc2: [
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
            ]
        },
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            value: '269',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
            ],
            desc2: [
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
            ]
        },
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            value: '269',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
            ],
            desc2: [
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
            ]
        },
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            value: '269',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
            ],
            desc2: [
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
            ]
        },
        {
            id: '1',
            heading: 'Graphic Designing',
            title: 'Logo Design',
            value: '269',
            desc1: [
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
                {
                    description1: '6 Logo Design Concepts'
                },
            ],
            desc2: [
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
                {
                    description2: '6 Logo Design Concepts'
                },
            ]
        },
    ]
    return (
        <div id='website-responsive'>
            <div className='left-padding8rem right-padding8rem '>
                <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>

                    {/* <> */}

                    {/* {pkg.map((pkg, i) => ( */}

                    <>
                        <div className='text-align-center1 pt-4 pt-lg-0 pt-md-3'>
                            <h2 className='fs-ss-32 fw-900 text-mulish'>
                                Graphic Designing<span className='text-ss-primary'> Packages </span>
                            </h2>
                        </div>

                        <Row className='mb-lg-5 pb-lg-5 mb-4 pb-4'>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Logo Design
                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>269</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>6 Logo Design Concepts</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Dedicated Designers</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>4 Revisions</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Logo animation</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Deliver in 24 Hours</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Final Files (.AI, .PSD, .EPS, .JPEG, .PNG, .PDF)</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Unique Custom Concepts</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Satisfaction Guaranteed</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Branding
                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>669</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>4 Custom Logo Design concepts</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Dedicated Designers</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Stationary Design Set</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Flyer</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 Pages Website</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>6 Copyright Images</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 Social Media Pages Creation</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>4 Banner Designs</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Deliver in 2 Weeks</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Satisfaction Guarantee</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mt-lg-5 mt-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Animation
                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>Starting from CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>349</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>2D / Whiteboard / Explainer animation videos</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Video</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Up-to 1 minute Video</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Script writing</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Finalized & Published</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Deliver in 4 Days</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% ownership rights</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Satisfaction</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mt-lg-5 mt-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Stationery
                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>Starting From CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>249</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>2 Business Card Design</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Letterhead Design</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Flyer Design / 1 Brochure Design</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Dedicated Designer</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>All File Formats</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 Free Revisions</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Deliver in 48 Hours</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Satisfaction
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>
                        </Row>


                        <div className='text-align-center1 pt-4 pt-lg-0 pt-md-3'>
                            <h2 className='fs-ss-32 fw-900 text-mulish'>
                                Web Development<span className='text-ss-primary'> Packages </span>
                            </h2>
                        </div>

                        <Row className='mb-lg-5 pb-lg-5 mb-4 pb-4'>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Customized
                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>Starting From CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>999</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>7-8 Web Pages</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Basic Contact / Inquiry Form</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Mobile Friendly Website</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Image Optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Speed Optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>WhatsApp & Facebook Chat Integration</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Social Media Linking</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>FREE up to 20 Stock Photos</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Website Testing
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Google Tools Integration
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Basic SEO setup
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Google Friendly Sitemap
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Delivery in 10-20 Working Days
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Unique Design

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Unique Content

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% Client Satisfaction
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />


                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            E-Commerce

                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>Starting From CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>1,999</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>7 Static Pages</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Up to 15 Categories & 150 Products</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>2 Basic Contact / Inquiry Form
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Newsletter Integration
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Free up to 20 Stock images</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Shopping Cart Integration
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Professionally written</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Payment Gateway (Stripe & PayPal)
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Customer's Login Area
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Dedicated User Roles

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Image & Speed Optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>WhatsApp & Facebook Chat Integration
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Mobile Friendly Website
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Website Testing

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Google Tools Integration
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Basic SEO setup

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Google Friendly Sitemap

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Delivery in 4-6 Weeks

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />


                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        <div className='text-align-center1 pt-4 pt-lg-0 pt-md-3'>
                            <h2 className='fs-ss-32 fw-900 text-mulish'>
                                Content Writing<span className='text-ss-primary'> Packages </span>
                            </h2>
                        </div>

                        <Row className='mb-lg-5 pb-lg-5 mb-4 pb-4'>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700 mb-0'>
                                            Blog Writing

                                        </h2>
                                    </div>


                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>69</span>

                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Up to 800 Words

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Content kickoff meeting

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Research-based

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 Keywords</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Revision

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 internal and 1 external linking
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 stock images
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% unique SEO-friendly content</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Professionally Written</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Delivery in 3- 5 days</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />


                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700 mb-0'>
                                            Creative Scriptwriting
                                        </h2>
                                    </div>


                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>129</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Research-based</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Scriptwriting 100 words</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Revision

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Thumbnail design
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% unique
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Covering all writing niches</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>On-time Delivery
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Professionally written</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Delivery in 2 Days
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mt-lg-5 mt-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Website Content

                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>399</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Keywords included (one focused one secondary keyword)</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Revision</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Competitor research</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>400 words per page
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>On-time Delivery
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Proofreading and Editing
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% unique SEO-friendly content</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Professionally Written</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Content kickoff meeting</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />


                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mt-lg-5 mt-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Article Writing

                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>199</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>800 Words Article
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Research-based</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 Keywords</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 Revision</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>1 internal and 1 external linking</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 stock images
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>100% unique SEO-friendly content</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Professionally written</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Content kickoff meeting</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Delivery in 3 - 5 days
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        <div className='text-align-center1 pt-4 pt-lg-0 pt-md-3'>
                            <h2 className='fs-ss-32 fw-900 text-mulish'>
                                Social Media<span className='text-ss-primary'> Packages </span>
                            </h2>
                        </div>

                        <Row className='mb-lg-5 pb-lg-5 mb-4 pb-4'>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700 mb-0'>
                                            Social Media Marketing
                                        </h2>
                                    </div>
                                    <div className=''>
                                        <span className='fs-ss-20 text-lato fw-500'>
                                            One Month Plan
                                        </span>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>Starting From <br />CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>499</span>
                                        <span className='fs-ss-15 text-lato fw-500'> /month</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Initial Social Media Consultation
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Profile setup and optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Social Media Competitive Analysis
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>3 Keywords</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Three Post Per week (FB, Instagram, LinkedIn, Twitter)
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Free Stock images & Trending Hashtags</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Approve/Edit posts before they are published</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Monthly Report progress</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Community Management (Setup event and promote, reply to comments, Query)</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Dedicated Social Media Account Manager</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />


                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700 mb-0'>
                                            YouTube Marketing
                                        </h2>
                                    </div>
                                    <div className=''>
                                        <span className='fs-ss-20 text-lato fw-500'>
                                            One Month Plan
                                        </span>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>Starting From <br />CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>399</span>
                                        <span className='fs-ss-15 text-lato fw-500'> /month</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Channel Creation</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Video title optimization</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Video Description
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Thumbnail design
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Covering all writing niches</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Optimized Channel (Cover & Profile image)</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Branded content writing</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Schedule Videos</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Analytics and reporting
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>YouTube keyword search
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mt-lg-5 mt-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Email Marketing


                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>Starting From <br />CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>399</span>
                                        <span className='fs-ss-15 text-lato fw-500'> /month</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Account setup
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Free stock photos</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Import Audience</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>4 Campaign Design
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Campaign Landing Page
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Schedule & send email blast

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Email Leads Management
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Every Campign analytic Report</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Mobile friendly emails
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Platform cost will be charged separately
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />


                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mt-lg-5 mt-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Google My Business

                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>Starting From <br />CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>269</span>
                                        <span className='fs-ss-15 text-lato fw-500'> /month</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>GMB Management
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Local & Niche Citation Building</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Local SEO Audits
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Service Area Optimization</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Multiple Photos + Posts</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Review Monitoring

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Detailed Automated Reporting</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>NAP Optimizations</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Q&A Management</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Dedicated Account Manager
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        <div className='text-align-center1 pt-4 pt-lg-0 pt-md-3'>
                            <h2 className='fs-ss-32 fw-900 text-mulish'>
                                SEO<span className='text-ss-primary'> Packages </span>
                            </h2>
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>Starting From CAD$ </span>
                            <span className='fs-ss-32 text-mulish fw-800 text-ss-primary mx-1'> 669</span>
                            <span className='fs-ss-15 text-lato fw-500'> / month</span>
                        </div>

                        <Row className='pb-lg-5 mb-lg-5 mb-4'>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Assessment & Technical

                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />


                                    {/* <hr className='mt-1' style={{ opacity: 0.1 }} /> */}

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Competitors Analysis</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Audit Before Starting Project</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Technical Optimization</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Content Audit
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Page Speed Optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Redirects Audit / Fix Broken Links 404</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Secure website with HTTPS</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>XML Sitemap Creation & Submission</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Robots.txt Optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>URL structures Optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Internal Linking
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Google Analytics, Search Console & Tag Manager Integration
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />



                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            On-Page & Off-Page
                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    {/* <div className=''>
                                        <span className='fs-ss-22 text-mulish fw-800 text-ss-primary'>Starting From CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>1,999</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} /> */}

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <b className='fs-ss-18 fw-bold text-mulish'>On - Page Optimization</b>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Keyword Research
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Targeting 10 - 20 Keywords</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Up to 10 Pages Optimization
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Title ,Tags & Meta descriptions
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Alt tags Optimization</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Mobile - Friendliness
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <b className='fs-ss-18 text-mulish fw-bold'>Off - Page Optimization</b>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Local Business Listings</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Social Bookmarking

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Customer's Login Area
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Relevant Link Building

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Article Submission

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Guest Posting

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Reporting (Weekly - Monthly)
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Support (Email - Phone)

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                        </Row>

                        <div className='text-align-center1 pt-4 pt-lg-0 pt-md-3 mb-lg-4 mb-4'>
                            <h2 className='fs-ss-32 fw-900 text-mulish'>
                                Paid Marketing<span className='text-ss-primary'> Packages </span>
                            </h2>
                        </div>


                        <Row className='mb-lg-5'>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch dgrid' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Google Ads Management


                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />
                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary lh-1'>Starting From <br />CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary mx-1'> 399</span>
                                        <span className='fs-ss-15 text-lato fw-500'> / 3 month</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc1.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Budget / Month Min $500
                                                (Budget is not included in package price)
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Industry Analysis
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Campaign Management</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Serach / Video Ads

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Google analytics integration & goal tracking
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Conversions Setup</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>2 Ad Groups / Up To 3 Ads Copies</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>2 Landing Pages</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>20 Keywords
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Daily Monitoring
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Campagin must run at least 3 months for better results
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />



                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                            <Col lg='6' className='mb-lg-0 mb-4 pb-lg-0 pb-md-4' >
                                <div className='m-4 p-4 card-stretch' style={{ boxShadow: '0px 2px 12px 2px rgba(0, 0, 0, 0.4)', borderWidth: '4px 0px 0px 0px', borderStyle:'solid', borderColor:'rgba(9,9,9,.1)'  }}>
                                    <div className=''>
                                        <h2 className='fs-ss-30 text-lato fw-700'>
                                            Facebook & Instagram Ads Management
                                        </h2>
                                    </div>

                                    <hr className='' style={{}} />

                                    <div className=''>
                                        <span className='fs-ss-18 text-mulish fw-800 text-ss-primary'>Starting From<br /> CAD$ </span>
                                        <span className='fs-ss-32 text-mulish fw-800 text-ss-primary'>399</span>
                                        <span className='fs-ss-15 text-lato fw-700 text-ss-black'>/ 3 months</span>
                                    </div>

                                    <hr className='mt-1' style={{ opacity: 0.1 }} />

                                    {/* {pkg.desc2.map((descr)=>( */}
                                    <>
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Ad Account Setup

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Facebook and Instagram ads consultation</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Ad Campaign Setup

                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Install Pixel to Ad account
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Ad Management</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Analytics and Tracking
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />

                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Ads Report / Support</span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />
                                        <div className=''>
                                            <FaCheck className='me-2 text-ss-primary' />
                                            <span className='fs-ss-14 text-mulish fw-500'>Budget is not included in package price
                                            </span>
                                        </div>
                                        <hr className='mt-1' style={{ opacity: 0.1 }} />


                                    </>
                                    {/* ))} */}

                                    <div className='d-flex justify-content-center mt-lg-5 mt-4'>
                                    <Link to='/packages-form' style={{ textDecoration:'none'}}>
                                        <Button className='btn-ss-package'>
                                            CHOOSE PLAN
                                        </Button>
                                    </Link>
                                    </div>
                                </div>

                            </Col>

                        </Row>

                    </>
                    {/* ))} */}



                </div>
            </div>
        </div>

    )
}

export default Packages
import React from 'react'
import { Button, Col, Form, Input, Row } from 'reactstrap'
import { GeoAltFill, Envelope } from 'react-bootstrap-icons'

const Info = () => {
    return (
        <div id='website-responsive'>
            <div className='left-padding10rem1 pe-lg-0 pe-3 py-lg-5 py-4' style={{ backgroundColor: 'transparent', backgroundImage: 'linear-gradient(270deg, #FE9B1F 15%, #FFFFFF00 15%)', }}>
                <Row className='py-lg-5'>
                    <Col xs='12' lg='5' className=''>
                        <div className=''>
                            <h2 className='fs-ss-40 text-philos fw-800'>
                                Contact Us
                            </h2>
                        </div>
                        <div className='mt-lg-4 mb-lg-5 mb-4 mt-3 mb-4'>
                            <p className='fs-ss-18 text-mulish text-ss-black fw-500'>
                                Feel free to contact us any time. We will get back to you as soon as we can!
                            </p>
                        </div>
                        <div className=''>
                            <Form>
                                <Input className='mb-lg-4 mb-3' type='text' name='name' placeholder='First Name and Last Name' required style={{ borderRadius: 0 }} />
                                <Input className='mb-lg-4 mb-3' type='email' name='email' placeholder='Email' required style={{ borderRadius: 0 }} />

                                <Input className='mb-lg-4 mb-3' type='textarea' name='message' placeholder='Message' style={{ borderRadius: 0 }} />

                                <Button type='submit' style={{ width: '100%', borderRadius: 0 }}>SEND</Button>
                            </Form>
                        </div>
                    </Col>

                    <Col lg='7' className='ps-lg-5 mt-lg-0 mt-5 d-flex justify-content-lg-end justify-content-sm-center'>
                        <div className='bg-img img-w8-1 d-flex align-items-center justify-content-center' style={{ backgroundImage: "url('/contact/Our-approach.png')", width: '', height: '100%' }}>
                            <div className='d-grid justify-content-center'>
                            <div className='mb-lg-5 mb-4 mt-lg-0 mt-4'>
                                <h3 className='fs-ss-40 text-philos fw-800 text-white'>
                                    Info
                                </h3>
                                </div>

                                <div className='mb-lg-5 mb-4'>
                                    <a target='_blank' rel='noreferrer' href='https://www.google.com/maps/place/57+Ketchum+Crescent,+Markham,+ON+L3S+3G3,+Canada/@43.8421658,-79.2715952,17z/data=!3m1!4b1!4m5!3m4!1s0x89d4d6949e00f1bd:0x66017c97e75aab4f!8m2!3d43.842162!4d-79.2694065' className='' style={{ textDecoration: 'none' }}>
                                        <GeoAltFill className='text-ss-primary fs-ss-30 me-3' />
                                        <span className='fs-ss-18 text-mulish fw-bold text-white'>
                                            Markham ON Canada
                                        </span>
                                    </a>
                                </div>
                                
                                <div className='mb-lg-5 mb-4'>
                                    <a href='mailto:care@zmcommunication.org' className='' style={{ textDecoration: 'none' }}>
                                        <Envelope className='text-ss-primary fs-ss-30 me-3'/>
                                        <span className='fs-ss-18 text-mulish fw-bold text-white'>
                                            care@zmcommunication.org
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Info
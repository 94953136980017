import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

const TopNotchServices = () => {
    return (
        <div id='website-responsive'>
            <div className='left-padding6rem1 right-padding6rem1 pb-md-5 pb-4 me-md5'>

                <div className=''>
                    <div className=''>
                        <center>
                            <h1 className='fs-ss-36 text-ss-black fw-800 text-philos'>
                                Top-Notch Digital Marketing Services In<span className='text-ss-primary'> Mississauga</span>
                            </h1>
                        </center>
                    </div>

                    <div className=''>
                        <Row className=''>
                            <Col xs='12' sm='6' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center '>
                                <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/Branding-1.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/branding/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                                Branding
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                            Branding is one of the essential steps of a successful business. It is the first impression you give your audience makes you stand out from your competition. Because your brand directly reflects what your customers should expect from you. It is your voice, mission, marketing materials, packaging, and website. Moreover, it is the main reason people will choose you over others. Simply put, branding is not just a logo.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                            <strong>Branding services in Mississauga</strong> are the wave of digital transformation that gives many opportunities to discover, know about up-to-date information, and select the best for your business. As a professional digital marketing agency for years, ZM Communication offers branding services to create top websites and make a solid pathway for brands to get their unique identity.
                                        </p>
                                    </div>
                                    <div className='my-4'>
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/branding/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                    </div>
                                </div>
                            </Col>

                            <Col xs='12' sm='6' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                            <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/Content-writing-1.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/content-writing/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                            Content Writing Mississauga
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                        Content writing services help any website quickly emerge from a modest research network to an impactful website infrastructure with exponentially up-to-date information. Consequently, content is a game changer and significantly contributes to attracting an audience to the website. Moreover, it redefines the way businesses reach and interact with customers.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                        This is the era of the digital boom; therefore, ZM Communication offers <strong> professional content writing services in Mississauga</strong> as a vital path to introducing your product to the public or your business to relevant clients.

</p>
                                    </div>
                                    <div className='my-4'>
                                        {/* <center> */}
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/content-writing/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                        {/* </center> */}
                                    </div>
                                    {/* </center> */}
                                </div>
                            </Col>

                            <Col xs='12' sm='6' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                            <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/Graphic-Designing-1.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/graphic-designing/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                                Graphic Designing Mississauga

                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                        Are you ready to elevate your brand and attract your dream audience through best <strong> graphic design services in Mississauga? </strong> ZM Communication finds beauty in simplicity and always strives to create appealing graphics for your website, social media posts, and unique designs for every client.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                        Because we work through planning, strategy, collaboration, and design, we will confidently help you attract your ideal audience. Our graphic design team will work directly with you and keep you informed every step. Undoubtedly, your brand story is unique, but we make it catchier to get your specific goals.

</p>
                                    </div>
                                    <div className='my-4'>
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/graphic-designing/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                    </div>
                                </div>
                            </Col>
                           
                            <Col xs='12' sm='6' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                            <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/Web-development.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/web-development/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                            Web Development<br/> Mississauga
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                        ZM Communication, as a finest <strong> web development agency in Mississauga, </strong> always focuses on the end goal and what type of website development is needed? At ZM Communication, we build websites to ensure they work for your business model, drive traffic, and get you the perfect results you are looking for.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                        Our professional and expert web development team in Mississauga can build you a unique and new site from scratch. We also assist with a website transfer to drive more traffic to your current website or get your existing website to convert to the new one for better traffic.
                                        </p>
                                    </div>
                                    <div className='my-4'>
                                        {/* <center> */}
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/web-development/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                        {/* </center> */}
                                    </div>
                                    {/* </center> */}
                                </div>
                            </Col>
                            <Col xs='12' sm='6' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                            <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/SEO-1.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/search-engine-optimization/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                            Search Engine Optimization Mississauga
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                        SEO provides considerable cost benefits to brands and businesses regarding ranking and affordability. So, using <strong> SEO services in Mississauga </strong> to get ranked on Google, grab the right audience, and convey the message to a more considerable number of people at just a fraction of the cost.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                        ZM Communication is <strong> best SEO agency in Mississauga </strong> to accelerate your brand's leads and sales. As one of the most reliable marketing firms in Mississauga, we use our top-notch SEO service as a key to crack the code of getting sales revenue while targeting effective keywords strategy and letting you embrace victory.

</p>
                                    </div>
                                    <div className='my-4'>
                                        {/* <center> */}
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/search-engine-optimization/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                        {/* </center> */}
                                    </div>
                                    {/* </center> */}
                                </div>
                            </Col>
                            <Col xs='12' sm='6' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                            <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/Social-Media.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/social-media-marketing/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                            Social Media Marketing Mississauga
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                        Previously, traditional marketing was popular and was an excellent way for the public to encounter any piece of marketing information. With time, <strong> digital marketing </strong> became a top priority. And now social media marketing services play a vital role in giving awareness about a company, brand, or business.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                        Social medial marketing performs a crucial role in people's lives, with the ever-growing need for a solid presence with up-to-date information. ZM Communication is <strong> best social media agency in Mississauga </strong> for attracting followers while providing them with the information they need.

</p>
                                    </div>
                                    <div className='my-4'>
                                        {/* <center> */}
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/social-media-marketing/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                        {/* </center> */}
                                    </div>
                                    {/* </center> */}
                                </div>
                            </Col>

                            <Col xs='12' sm='6' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                            <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/animation-1.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/video-animation/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                                Video Animation
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                        Video animation is a great strategy to convey your message to your audience in an attractive way. It does not just engage your customers; it inspires them to make purchases and helps your business maximize profits.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                        Whether trying to sell or buy any product, video animation can bridge the gap between your ideas and the audience’s interest. ZM Communication provides you <strong> video animation services in Mississauga, </strong> and we create incredible animated videos to help you grow your business. With our rich expertise in delivering design projects, we can add immense value to your brand and help you sustain your place at the top.
                                        </p>
                                    </div>
                                    <div className='my-4'>
                                        {/* <center> */}
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/video-animation/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                        {/* </center> */}
                                    </div>
                                    {/* </center> */}
                                </div>
                            </Col>
                            <Col xs='12' sm='12' md='6' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                            <div className='text-center text-lg-start'>
                                    {/* <center> */}
                                    <div className='ps-0'>
                                        <img src='home/Paid-Marketing.png' alt='' className='' width='90px' />
                                    </div>
                                    <div className='pt-3 pb-2'>
                                        <h3 className=''>
                                            <Link to='/services/ppc-marketing/' className='text-lato fs-ss-25 text-ss-primary fw-700' style={{ textDecoration: 'none' }}>
                                            PPC Marketing In Mississauga
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mb-0'>
                                        Advertisers are commonly referred to as sources in the parlance of PPC marketing services in the GTA. As a best <strong> digital marketing agency in Mississauga, </strong> we are on the higher climb and incorporate search result ads and email marketing campaigns. In addition, anything that includes PPC marketing for customer feedback is essential.
                                        </p>
                                    </div>
                                    <div className='me-md-4' style={{ textAlign: 'justify' }}>
                                        <p className='fs-ss-15 fw-500 text-mulish mt-0'>
                                        Furthermore, Pay-per-click marketing has become an effective strategy for any brand or company. Being the digitalized version of the standard advert format utilized in print and broadcast media, it helps the brand appear in recommended results and searches based on the target audience’s previous interactions on the web.
                                        </p>
                                    </div>
                                    <div className='my-4'>
                                        {/* <center> */}
                                            <Button className='btn btn-ss-secondary1 px-3 rounded-1 py-1 fw-500 text-white ' style={{ borderRadius: '0px' }}>
                                                <span className='fs-ss-14-2 text-mulish fw-500'>
                                                <Link to='/services/ppc-marketing/' className='fs-ss-14-2 text-white text-mulish fw-500' style={{ textDecoration: 'none' }}>
                                                    Learn more
                                                </Link>
                                                </span>
                                            </Button>
                                        {/* </center> */}
                                    </div>
                                    {/* </center> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopNotchServices
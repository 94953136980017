import React from 'react'

const WhatWeStandFor = () => {
  return (
    <div id='website-responsive'>
        <div className='py-md-4 py-4'>
            <div className=''>
                <h3 className='fs-ss-35 text-philos fw-800 text-ss-black py-4 py-lg-5'>
                    <center>
                        What We STAND For
                    </center>
                </h3>
            </div>

            <div className='' style={{ backgroundColor:'black', opacity:0.85}}>
                <div className='px-md-5 px-4 py-md-5 py-4'>
                    <center>
                        <img src='about/we-stand-for-1024x247.png' alt='' className='img-w13' />
                    </center>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhatWeStandFor
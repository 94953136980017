import React from 'react'
import { Col, Row } from 'reactstrap'

const Results = () => {
  return (
    <div id='website-responsive' style={{ overflow:'hidden'}}>
        <div className='left-padding8rem1 right-padding6rem1 py-lg-5 py-md-4 py-4'>
            <Row className='py-lg-4'>
                <Col lg='6' className='pe-lg-2'>
                    <div className=''>
                        <div className=''>
                            <h3 className='fs-ss-36 text-philos text-ss-primary fw-800'>
                                Results
                            </h3>
                        </div>

                        <div className='mt-lg-4'>
                            <p className='fs-ss-15 text-mulish fw-500 lh-1-8 '>
                            ZM Communication monitored the overall success in terms of activity monitoring of staff and students, better and easier course scheduling, improvement of relations with potential students, and better strategic decisions to boost lead generation. While working with NetPLUS College and implementing the CRM solution, the process maximized the team’s time and productivity. 
                            </p>
                        </div>

                        <div className='mt-lg-4 pe-lg-2'>
                            <p className='fs-ss-15 text-mulish fw-500 lh-1-8 '>
                            Today, NetPLUS College continues to thrive in the education industry, with more and more students joining them and successfully graduating while “100% job-ready” for future professional successes.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg='6' className='mt-lg-5'>
                    <div className=''>
                        <img src='case-study/NetPlus-Case-Study-ZM-pic-01-v1.jpg' alt='' width='100%' className='' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Results
import React from 'react'
import { ArrowRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

const CommunityCommitment = () => {
    return (
        <div id='website-responsive'>
            <div className='bg-img-1 padding-home-2' style={{ backgroundImage: "url('home/ZM-Healthcare.jpg')", width: '100%', height: '100%', backgroundSize:'', backgroundRepeat:'no-repeat', backgroundPosition:'' }}>
                <div className='d-grid justify-content-center  justify-content-md-start  justify-content-lg-center align-items-center text-center'>
                <div className=''>
                    <h2 className='fs-ss-40 text-philos fw-600 text-white ' style={{ fontStyle: 'italic', letterSpacing: '1px' }}>
                        A Commitment to the Community,
                    </h2>
                </div>
                <div className=''>
                    <h2 className='fs-ss-26 text-mulish fw-500 text-white ' style={{ fontStyle: '', letterSpacing: '1px' }}>
                        <center>
                            bringing the future to healthcare
                        </center>
                    </h2>
                </div>

                <div className='mb-2 mt-md-5 mt-4'>
                <center>
                    <Link to="/contact" className='btn btn-ss-secondary-white ' style={{ borderRadius: '0px' }}>
                        <span className='fs-ss-18 text-mulish fw-500'>
                            Contact Us
                        </span>
                        <ArrowRight className='ms-2' />
                    </Link>
                    </center>
                </div>
            </div>
            </div>
        </div>
    )
}

export default CommunityCommitment
import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import BannerMarketing from '../components/digital-marketing/BannerMarketing'
import BlueprintOfSuccess from '../components/digital-marketing/BlueprintOfSuccess'
import TopNotchServices from '../components/digital-marketing/TopNotchServices'
import BrilliantMinds from '../components/digital-marketing/BrilliantMinds'
import WhyZM from '../components/digital-marketing/WhyZM'

const DigitalMarketingPage = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <div style={{ overflow:'hidden', backgroundImage:'url("bg-img/MicrosoftTeams-image.png")', width:'100%', position:'', backgroundAttachment:'fixed'}}>

        <BannerMarketing/>
        <BlueprintOfSuccess/>
        <TopNotchServices/>
        <BrilliantMinds/>
        <WhyZM/>
    </div>
    </>
  )
}

export default DigitalMarketingPage
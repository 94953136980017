import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import HealthcareMainBanner from '../components/healthcare-solutions/HealthcareMainBanner'
import ImprovingPatientExperience from '../components/healthcare-solutions/ImprovingPatientExperience'
import CommitedToCare from '../components/healthcare-solutions/CommitedToCare'
import UnionOfTechnologyAndHealthcare from '../components/healthcare-solutions/UnionOfTechnologyAndHealthcare'
import HealtcareITSolutions from '../components/healthcare-solutions/HealtcareITSolutions'

const HealthcareSolutionPage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <div style={{ overflow:'hidden', backgroundImage:'url("/bg-img/MicrosoftTeams-image.png")', width:'100%', position:'', backgroundAttachment:'fixed'}}>
        <HealthcareMainBanner/>
        <ImprovingPatientExperience/>
        <CommitedToCare/>
        <UnionOfTechnologyAndHealthcare/>
        <HealtcareITSolutions/>
    </div>
    </>
  )
}

export default HealthcareSolutionPage
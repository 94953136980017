import React from 'react'
import { ArrowRight } from 'react-bootstrap-icons'
import { Button, Col, Row } from 'reactstrap'

const BlueprintOfSuccess = () => {
  return (
    <div id='website-responsive' style={{ position:''}}>
    <div className='left-padding7rem1 right-padding8rem1 py-md-5 py-4 me-md-5'>
        <Row className=''>
            <Col sm='12' md='12' lg='7' className=''>
                <div className='me-md-4'>
                    <div className='me-md-5'>
                        <h2 className='text-philos fs-ss-36 text-ss-black fw-800'>
                            Scoping the Blueprint of your Success
                        </h2>
                    </div>
                    <div className='pt-md-3 pt-lg-0 pt3 me-md-5' style={{ textAlign:'justify'}}>
                        <p className='fs-ss-16 text-mulish fw-500 lh-1-8 '>
                            Starting a new business in Mississauga on a small, medium, or large scale or launching a new brand seems straightforward, but it is not easy. You have to spend money and develop impactful digital marketing  tactics; on the other hand, the most critical step is your solid online presence. But how do you make an online identity to target the right audience and make your business successful? The answer is assistance from the <strong>best digital marketing agency in Mississauga.</strong> 
                        </p>
                    </div>

                    <div className='pt-md-2 pt-lg-0 pt2 me-md-5' style={{ textAlign:'justify'}}>
                        <p className='fs-ss-16 text-mulish fw-500 lh-1-8 '>
                            Get more traffic with best seo services and tactics developed by ZM Communication best digital marketing agency in Mississauga to acquire more customers and generate impressive ROI. ZM Communication works for businesses of all sizes. We are committed to creating result-oriented and compelling digital marketing campaigns, commercials & brand content.

                        </p>
                    </div>
                </div>
            </Col>
            
            <Col sm='12' md='12' lg='5' className='mt-lg-5'>
                <div className=''>
                    <img src='marketing/ZM-Mississauga-images-1.jpg' alt='best digital Marketing agency in Mississauga' className='img-home-1' height='' width='' />
                </div>
            </Col>
        </Row>

        </div>
        </div>
  )
}

export default BlueprintOfSuccess
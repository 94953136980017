import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import BannerImg from '../../assets/images/bannners/Packages.png'

const MainBannerPackages = () => {
  return (
    <div id='website-responsive'>
        {/* <div className=''  style={{ backgroundColor:'#000000', opacity:5, zIndex:2222 }}> */}
    <div className='bg-img padding-packages' style={{ backgroundImage: `url(${BannerImg})` , height:'', width:'100%',   }}>
        <div className=''>
            <h3 className='fs-ss-24 text-mulish text-white fw-500' style={{ letterSpacing:'1px'}}>
                Connecting You To The Digital World With
            </h3>
        </div>
        <div className=' mt4' style={{}}>
            {/* <span className='fs-ss-70 text-ss-primary text-philos'> */}
                <TypeAnimation sequence={['Our Packages',1000,'',200]}
                wrapper="span"
                speed={1}
                deletionSpeed={0}
                className='fs-ss-60 textss-primary fw-800 text-philos ms-1 ms-md4'
                style={{color:'#FE9B1F',  }}
                repeat={Infinity}
                cursor={false} />
           
        </div>
    {/* </div> */}
    </div>
    </div>
  )
}

export default MainBannerPackages
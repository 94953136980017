import React from 'react'
import { ArrowRight, Dot, List } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'
import { Col, Row } from 'reactstrap'
import BlogImg1 from '../../assets/images/blogs/Digital-marketing-save-or-boost-your-small-business-Blog-14-image1-ZM-Communication.jpg'
import BlogImg2 from '../../assets/images/blogs/Which-technologies-are-best-for-web-development-Blog-13-image1-ZM-Communication.jpg'
import BlogImg3 from '../../assets/images/blogs/ZM-Blog-11.jpg'
import BlogImg4 from '../../assets/images/blogs/ZM-Blog-10-image.jpg'
import BlogImg5 from '../../assets/images/blogs/Blog-9-Image.jpg'
import BlogImg6 from '../../assets/images/blogs/Tips-for-effective-content-writing-featured-image.jpg'
import BlogImg7 from '../../assets/images/blogs/SEO-tools-for-your-website.jpg'
import BlogImg8 from '../../assets/images/blogs/PPC-Marketing-Services.jpg'
import BlogImg9 from '../../assets/images/blogs/Video-and-2D-3D-Animation.jpg'
import BlogImg10 from '../../assets/images/blogs/Content-Writing-Services.jpg'
import BlogImg11 from '../../assets/images/blogs/Graphic-Design-Service.jpg'
import BlogImg12 from '../../assets/images/blogs/Professional-SEO-services.jpg'
import BlogImg13 from '../../assets/images/blogs/brand-marketing-strategy.jpg'
import BannerImg from '../../assets/images/bannners/Blog.png'

const Blogs = () => {


    const blogs = [
        {
            img:`${BlogImg1}`,
            alt:'grow small business online',
            title:'How Can Digital Marketing Grow Small Business Online?',
            date:'October 13, 2022',
            agency:'Digital Marketing Agency',
            short_desc:'How To Grow Small Business Online? When small businesses start, they frequently concentrate on finding ways',
            link:'/how-can-digital-marketing-grow-small-business-online/',
        },
        {
            img:`${BlogImg2}`,
            alt:'cutting edge web development technologies',
            title:'What Are the Cutting Edge Web Development Technologies?',
            date:'October 12, 2022',
            agency:'Digital Marketing Agency, Web Development',
            short_desc:'It’s All About – Technologies Used For Web Development The exponential evolution in web development is',
            link:'/what-are-the-cutting-edge-web-development-technologies/',
        },
        {
            img:`${BlogImg3}`,
            alt:'grow small business online',
            title:'9 Reasons Why You Should Outsource Your Social Media Management',
            date:'May 27, 2022',
            agency:'Social Media Marketing',
            short_desc:'The traditional marketing model relies on getting exposure from the product’s unique selling point and then',
            link:'9-reasons-why-you-should-outsource-your-social-media-management/',
        },
        {
            img:`${BlogImg4}`,
            alt:'Social media management',
            title:'The Innumerable Benefits of Employing the Services of a Digital Marketing Agency',
            date:'May 13, 2022',
            agency:'Digital Marketing Agency',
            short_desc:'As a business owner, there are high chances that you already know what digital marketing means',
            link:'/the-innumerable-benefits-of-employing-the-services-of-a-digital-marketing-agency/',
        },
        {
            img:`${BlogImg5}`,
            alt:'Digital marketing agency in Toronto',
            title:'How to use social media platforms for business?',
            date:'April 23, 2022',
            agency:'Social Media Marketing',
            short_desc:'Shaping social media, the digital future of Your Brand With so many social media platforms, channels,',
            link:'/how-to-use-social-media-platforms-for-business/',
        },
        {
            img:`${BlogImg6}`,
            alt:'grow small business online',
            title:'The next big thing in content writing: 9 tips for effective, professional, and high-quality writing',
            date:'March 18, 2022',
            agency:'Content Writing',
            short_desc:'Content that is reliable, interesting, and of excellent quality has a more significant effect on the',
            link:'the-next-big-thing-in-content-writing-9-tips-for-effective-professional-and-high-quality-writing/',
        },
        {
            img:`${BlogImg7}`,
            alt:'grow small business online',
            title:'Step up and Measure Your Brand’s Success With 7 Essential SEO Tools',
            date:'March 7, 2022',
            agency:'Search Engine Optimization',
            short_desc:'In every industry, achieving goals and setting objectives are essential strategies. But what if you’re using',
            link:'/step-up-and-measure-your-brands-success-with-7-essential-seo-tools/',
        },
        {
            img:`${BlogImg8}`,
            alt:'grow small business online',
            title:'PPC Marketing Services For Your Brand And Business',
            date:'February 21, 2022',
            agency:'PPC Marketing',
            short_desc:'Starting a new business and publicizing it was challenging some decades ago as there was no',
            link:'/ppc-marketing-services-for-your-brand-and-business/',
        },
        {
            img:`${BlogImg9}`,
            alt:'grow small business online',
            title:'Video and 2d-3d animation services for your brand and products',
            date:'February 2, 2022',
            agency:'2D-3D Video Animation',
            short_desc:'2D-3D animation videos are always fun to watch. Now they have taken an interesting twist by',
            link:'/video-and-2d-3d-animation-services-for-your-brand-and-products/',
        },
        {
            img:`${BlogImg10}`,
            alt:'grow small business online',
            title:'Website Content Writing Services and Techniques',
            date:'January 19, 2022',
            agency:'Content Writing',
            short_desc:'You’ve just launched a new business and want to attract customers by offering the most effective',
            link:'/website-content-writing-services-and-techniques/',
        },
        {
            img:`${BlogImg11}`,
            alt:'grow small business online',
            title:'Graphic Design Services: The Power of Digital Communication',
            date:'January 12, 2022',
            agency:'Graphic Designing',
            short_desc:'People didn’t have any digital presence a few decades ago, so increasing sales or flourishing business',
            link:'/graphic-design-services-the-power-of-digital-communication/',
        },
        {
            img:`${BlogImg12}`,
            alt:'grow small business online',
            title:'Drive traffic to your website with professional SEO services',
            date:'January 4, 2022',
            agency:'Search Engine Optimization',
            short_desc:'Many people wonder about SEO. What is Search Engine Optimization, and how does it function? Search',
            link:'/drive-traffic-to-your-website-with-professional-seo-services/',
        },
        
    ]

    return (
        <div id='website-responsive'>
        <div className=''>
            <div className='bg-img padding-faq' style={{ backgroundImage: `url(${BannerImg})`, height: '', width: '100%', }}>
                <div className=''>
                    <h3 className='fs-ss-24 text-mulish text-white fw-500' style={{ letterSpacing: '1px' }}>
                        Creative, Innovative, And Informative
                    </h3>
                </div>
                <div className=' mt4' style={{}}>
                    {/* <span className='fs-ss-70 text-ss-primary text-philos'> */}
                    <TypeAnimation sequence={['Blogs', 1000, '', 200]}
                        wrapper="span"
                        speed={1}
                        deletionSpeed={0}
                        className='fs-ss-60 textss-primary fw-800 text-philos ms-1 ms-md4'
                        style={{ color: '#FE9B1F', }}
                        repeat={Infinity}
                        cursor={false} />

                </div>
                {/* </div> */}
            </div>

            <div className='left-padding8rem1 right-padding8rem1 py-lg-5 py-4'>
                <div className='row justify-content-evenly'>
                {blogs.map((blog)=>(
                    <div className='mb-lg-5 mb-4 px-0 w-30 rounded-3' style={{ backgroundColor:'#F5F5F5', boxShadow:'0px 0px 10px 0px rgba(0,0,0,0.5)'}}>
                            <div className='image-box'>
                            <Link to={blog.link} className='' style={{ textDecoration:'none'}}>
                            <img className='d-none d-lg-block' src={blog.img} alt={blog.alt} style={{height:"33vh"}} />
                            <img className='d-block d-lg-none w-100' src={blog.img} alt={blog.alt} />
                            </Link>
                            </div>


                            <div className='py-lg-5 py-4 px-3'>
                            <div className=''>
                                <h2 className='fs-ss-20 fw-600 mb-0'>
                                    <Link to={blog.link} className='text-philos text-ss-black text-hover-blue' style={{ textDecoration:'none'}}>
                                        {blog.title}
                                    </Link>
                                </h2>
                            </div>

                            <div className='mb-2'>
                                <span className='text-ss-primary fs-ss-12 text-lato'>
                                    {blog.date}
                                </span>
                                <Dot/>
                                <List className='text-ss-primary fs-ss-12 text-lato ms-1 me-2'/>
                                <span className='text-ss-primary fs-ss-12 text-lato'>
                                    {blog.agency}
                                </span>
                            </div>

                            <div className=''>
                                <p className='fs-ss-14 text-mulish' style={{ color:'#727472' }}>
                                {blog.short_desc}
                                </p>
                            </div>

                            <div className=''>
                            <Link to={blog.link} className='text-ss-primary fs-ss-15 text-lato' style={{ textDecoration:'none'}}>
                                Read More
                                <ArrowRight className='ms-1'/>
                            </Link>
                            </div>
                            </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
        </div>
    )
}

export default Blogs
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { ArrowRight, ChevronRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

const CampaignsServices = () => {
  return (
    <div id='website-responsive' style={{ position:''}}>
        <div className='left-padding10rem1 right-padding10rem1 py-md-5 py-4'>
            <Row className=''>
                <Col sm='12' md='12' lg='6' className=''>
                    <div className='me-md-5'>
                        <div className='me-md-5'>
                            <h3 className='text-philos fs-ss-38 text-ss-black fw-800'>
                                We Create Winning Digital Marketing Campaigns
                            </h3>
                        </div>
                        <div className='pt-md-4 pt-3 me-md-5'>
                            <p className='fs-ss-18 text-mulish fw-500 '>
                                Step into digital mastery with<strong> ZM Communication,</strong> – Canada's beacon of digital marketing excellence. Just as skilled artists create masterpieces, we craft successful digital marketing campaigns. Our journey mirrors the ascent of trailblazers, navigating challenges with finesse and propelling clients toward unparalleled success. Situated in the heart of Canada, we weave strategic brilliance to nurture<Link to='/services/branding' className='text-ss-primary' style={{ textDecoration:'none'}}> brand growth</Link> and foster enduring client relationships. Our forte lies in innovative execution that follows trends and sets new benchmarks. Your brand's success narrative begins here – join us in reshaping the digital landscape, one campaign at a time.
                            </p>
                        </div>
                        <div className='my-4 my-lg-5'>
                            <Link to="/about-us" className='text-decoration-none fs-ss-17 px-4 text-mulish fw-500 fw-bold text-primary-hover' style={{ border:"2px solid #FE9B1F", padding:"14px"}}>
                                <span className=' '>
                                    Learn More
                                </span>
                                <ChevronRight className='ms-2 fs-ss-20'/>
                            </Link>
                        </div>
                    </div>
                </Col>
                
                <Col sm='12' md='12' lg='6' className=''>
                    <div className='pt-3'>
                        <img src='home/What-we-do.png' alt='Digital Marketing Agency Toronto' className='img-home-1' height='' width='' />
                    </div>
                </Col>
            </Row>

            <div className='mt-md-5 mt-4 mb-2'>
                <div className=''>
                    <h1 className='fs-ss-38 text-ss-black fw-800 text-philos text-center text-md-start'>
                        Services we provide
                    </h1>
                </div>

                <Row className=''>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center '>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/Branding-1.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/branding' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Branding
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/Graphic-Designing-1.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/graphic-designing' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Graphic Designing
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/Content-Writing-1.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/content-writing' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Content Writing
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/Web-development.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/web-development' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Web Development
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/SEO-1.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/search-engine-optimization' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Search Engine Optimization
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/Healthcare-Icon.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/healthcare-solutions' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Healthcare Solutions
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/Social-Media.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/social-media-marketing' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Social Media Marketing
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='6' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/animation-1.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/video-animation' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            Video Animation
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                    <Col xs='12' sm='12' md='4' lg='4' className='my-md-4 my-4 d-flex justify-content-center'>
                        <div className=''>
                            <center>
                                <div className=''>
                                    <img src='home/Paid-Marketing.png' alt='' className='' width='' />
                                </div>
                                <div className=''>
                                    <h3 className=''>
                                        <Link to='/services/ppc-marketing' className='text-mulish fs-ss-18 text-ss-black fw-bold' style={{ textDecoration:'none'}}>
                                            PPC Marketing
                                        </Link>
                                    </h3>
                                </div>
                            </center>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default CampaignsServices
import React, { useState } from "react"
import {Link }from 'react-router-dom'

const ReadMoreOrLess = (props) =>{

    const [isReadMoreshown, setReadMoreShown] = useState(false)
    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState)
    }
    return (
    <>
            <p className="fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8">
               {isReadMoreshown ? props.Para : props.Para.substr(0,props.limit)} 
            </p>
            <button onClick={toggleBtn} className="fs-ss-16 text-mulish fw-bold bg-white text-ss-black px-3" style={{border:"2px solid #FE9B1F"}}>
                {isReadMoreshown ? "Read Less" : "Read More"}
            </button>
    </>
    )
}

export default ReadMoreOrLess;
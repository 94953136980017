import React from 'react'
import { Col, Row } from 'reactstrap'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'

const WhyZM = () => {
    return (
        <div id='website-responsive'>
            <div className='left-padding8rem1 right-padding6rem1 py-md-5 py-4 me-md5'>
                <Row className='gy-4'>
                    <Col lg='6' className='pt-md-4'>
                        <div className=''>
                            <div className=''>
                                <h3 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                                    Why ZM Communication?
                                </h3>
                            </div>

                            <div className='mt-lg-4 mt-3'>
                            <div className='d-flex align-items-center mb-2'>
                                <BsFillArrowRightCircleFill className='text-ss-primary me-4' />
                                <span className='fs-ss-18 text-mulish fw-500'>
                                    Digital Marketing Experts</span>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <BsFillArrowRightCircleFill className='text-ss-primary me-4' />
                                <span className='fs-ss-18 text-mulish fw-500'>
                                    Transparent Progress Reports</span>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <BsFillArrowRightCircleFill className='text-ss-primary me-4' />
                                <span className='fs-ss-18 text-mulish fw-500'>
                                    Focus on Your Business Goals
                                </span>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <BsFillArrowRightCircleFill className='text-ss-primary me-4' />
                                <span className='fs-ss-18 text-mulish fw-500'>
                                    Latest Digital Marketing Resources
                                </span>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <BsFillArrowRightCircleFill className='text-ss-primary me-4' />
                                <span className='fs-ss-18 text-mulish fw-500'>
                                    Published Portfolio
                                </span>
                            </div>
                            <div className='d-flex align-items-center mb-2'>
                                <BsFillArrowRightCircleFill className='text-ss-primary me-4' />
                                <span className='fs-ss-18 text-mulish fw-500'>
                                    Effective Pricing Model
                                </span>
                            </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg='6' className=''>
                        <div className=''>
                            <img src='marketing/ZM-Mississauga-images-2.jpg' alt='Finest digital marketing agency' className='img-w5'  />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default WhyZM
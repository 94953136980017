import React, { useEffect } from 'react'
import Header2 from '../../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ServicesBanner from '../../components/haseeb/ServicesBanner'
import BannerImg from '../../assets/images/bannners/SearchEngineOptimization.png'
import ImageBorderStyleRight from '../../components/haseeb/ImageBorderStyleRight'
import ImageBorderStyleLeft from '../../components/haseeb/ImageBorderStyleLeft'
import Img1 from '../../assets/images/seo/Img1.jpg'
import Img2 from '../../assets/images/seo/Img2.jpg'
import Img3 from '../../assets/images/seo/Img3.jpg'
import Img4 from '../../assets/images/seo/Summit-our-drive.jpg'
import Img5 from '../../assets/images/seo/Img5.jpg'
import MiddleBannerPrimary from '../../components/haseeb/MiddleBannerPrimary'
import MiddleBannerSecondary from '../../components/haseeb/MiddleBannerSecondary'
import { ChevronRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { FaDotCircle } from 'react-icons/fa'

const SearchEngineOptimization = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <ServicesBanner
    BannerImg={BannerImg}
    SubTitle="Initializing The Growth Factor"
    Title="Search Engine Optimization"
    />
<section id='website-responsive' className="container-fluid px-0">
<div id='website-responsive'>
        <div className='container-fluid ps-lg-5 ps-4 py-lg-5 py-4'>
            <Row className=''>
                <Col lg='3' className=''>
                    <div className='pb-md-3 pb-lg-0 pb-3'>
                        <div className='d-flex align-items-center'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/healthcare-solutions' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Healthcare Solutions
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/branding/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Branding
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/graphic-designing' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Graphic Designing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/content-writing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Content Writing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/web-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Web Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/app-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    App Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                            <Link to='/services/search-engine-optimization/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-24 text-mulish fw-500 text-ss-primary'>
                                    Search Engine Optimization
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                            <Link to='/services/social-media-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Social Media Marketing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/video-animation/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Video Animation
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/ppc-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                PPC Marketing
                            </span>
                                </Link>
                        </div>
                    </div>
                </Col>

                <Col className='ps-lg-4 d-none d-lg-block'>
                        <div className='d-block d-lg-flex align-items-start'>
                          <div className='w-75'>
                          <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Explore Our Comprehensive SEO Package Options
                            </h2>
                        </div>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            Take a journey to boost your online presence and grow naturally with <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication's </Link> full range of SEO packages. Our team of SEO experts is dedicated to improving every part of your online presence, from finding the right keywords to making your content better and getting more links to your site. We know every business is different, so <Link className='text-decoration-none text-ss-primary' to="/packages/"> our packages </Link> are made just for you, considering your wants and needs. We use data to see how well your website is doing, find ways to improve it, and use strategies to help it appear higher on search engines.
                            </p>
                            </div>
                            <div className='px-lg-5'>
                            <img className='' src={Img4} alt="Seo" style={{width:"400px"}} />
                        </div>
                    </div>
                </Col>
                
                <Col className='d-block d-lg-none'>
                          <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Explore Our Comprehensive SEO Package Options
                            </h2>
                        </div>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            Take a journey to boost your online presence and grow naturally with <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication's </Link> full range of SEO packages. Our team of SEO experts is dedicated to improving every part of your online presence, from finding the right keywords to making your content better and getting more links to your site. We know every business is different, so <Link className='text-decoration-none text-ss-primary' to="/packages/"> our packages </Link> are made just for you, considering your wants and needs. We use data to see how well your website is doing, find ways to improve it, and use strategies to help it appear higher on search engines.
                            </p>
                            <div className='px-lg-5'>
                            <img className='w-100' src={Img4} alt="Seo"/>
                    </div>
                </Col>
            </Row>
        </div>
    </div>


    <section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             The Ascending Begins:
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             With collaborative <Link className='text-decoration-none text-ss-primary' to="/services/social-media-marketing/"> digital marketing </Link> strategies to organic search, SEO weighs opportunities to provide clients with the performance they need for growth. There is no harm in saying that practical SEO ideas can make your business unmissable and soaring. Remember, it’s not only about ranking anymore; it’s about being top of the search and competition. 
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerPrimary
    Title={<> <span className='text-white'>Looking for authentic and profitable Organic
    Search Ranking Results? </span></>}
    Para="Just follow the stepping-stones and optimize your website to rank higher in search results."
    />
    
    <section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-center gy-4'>
        <div className="col-11 col-md-5">
          <img className='w-100' src={Img5} alt="" />
        </div>
        <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Drive Organic Traffic and Revenue with <span className='text-ss-primary'> EXPERT SEO SERVICES IN CANADA </span>
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             We do everything from finding the best keywords to strengthening your site and looking at data to make intelligent choices. Our approach to SEO in Canada works for what you want and the type of business you have. It doesn’t matter if you’re a local business trying to be known in your area or a big company wanting to be known all over the country. Our SEO services help your brand do well online. We care about making good results that last, and we’re good at it. ZM Communication is the team to trust when you want to use SEO to be successful in Canada and beyond.           
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerSecondary
    Title="Research, Strategy, Processes & Execution"
    Para={<>Since the goal is to drive better, more targeted organic traffic through digital marketing strategies to your website – to impact your revenue growth month over month directly, we use SEO strategies to make your effective online presence and to grow your business naturally. So, at ZM communication, we provide search engine optimization services in Canada under one roof and robust solutions with traditional digital PR, premium content, and our best-in-the-business staff & support from Canada and worldwide.
    <div className='pt-4'>
    <Link to="/packages" className="text-decoration-none text-white fs-ss-18 fw-500 border-2 border-white border py-2 px-3"> View Packages <ChevronRight className='fs-ss-22'/> </Link>
    </div>
    </>}
    />
    <div className='pt-4 pt-lg-0'/>
    <ImageBorderStyleLeft
    Img={Img1}
    Title="The Wanted Traffic"
    Para="More targeted traffic means more leads and sales in the long-term businesses. It's that simple! Though it might vary from system to system, some of our clients get our top-notch local SEO services to boost sales and grow their organization. Furthermore, making an online presence is the first and essential part of marketing strategy."
    />
    
    <ImageBorderStyleRight
    Img={Img2}
    Title="Tracking Leads"
    Para="Marketing requires focus on all aspects, with built-in analytics and lead tracking, it becomes easier to delve in with critical insights into your target audience while optimizing your website for conversions."
    />

    <ImageBorderStyleLeft
    Img={Img3}
    Title="Authority Building"
    Para="Show Google you're a relevant, reputable business. Build trust by transforming your website into a place of thought leadership. Establish credibility through exposure on high-authority, industry-related websites, and mentions from news sources."
    />

<section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-4'>
        <div className='mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Winning Blog Content Strategy
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             We craft 100% original content which will be relevant to your company’s audience to improve your website traffic, user engagement, and conversions.
             </p>
        </div>
        </div>

        <div className='mb-4'>
        <div className='mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Crafted To Soar
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             As a professional provider of search engine optimization services in Canada, we ensure that the content we provide for the clients is unique, creative, and appealing with proper heading tags, engaging titles, and meta descriptions. Other formats make the article easy to read and are eventually valued by search engines and users. As we all know, <Link className='text-decoration-none text-ss-primary' to="/services/content-writing"> SEO-friendly </Link> content is the weapon to make a solid online presence and get a high rank on the Google search bar. You can grow your business and sales effectively by adding appealing and engaging content to the website. 
             </p>
        </div>
        </div>

        <div className='mb-4'>
        <div className='mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Impactful Content
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             When it comes to quality, there is no compromise. To increase ranking, we’ll go above and beyond. The blogs will be impressive up-to-date, and unique in style. We strongly believe that good content can enhance SEO efforts in building authority and credibility while generating traffic to your site to <Link className='text-decoration-none text-ss-primary' to="/services/ppc-marketing/"> improve lead generation. </Link>
             </p>
        </div>
        </div>

        <div className='mb-4'>
        <div className='mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Tailored For Business Growth
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Are you ready to take control of your business and ascend to heights of success? Follow the simple mantra: develop a Growth Plan that practically steers himself and his business through SEO services to drive a consistent inrush of sales. If you have a great website and captivating web designs but don’t put any effort into technical SEO or link building, you can’t get the targeted audience. So, if you want to increase your website traffic, having a professional SEO team is a must. <br/><br/>
             
             So, what’s the wait? Let’s Get Started!
             We at Z.M. Communication understand the potential of your site and will work with you to attract your targeted audience and qualified traffic. From domain authority to site speed, we analyze every variable that affects SERPs, achieving results that matter. <strong> Let’s initiate the journey of success today with the help of our high-quality Search engine optimization services in Canada!!! </strong>
             </p>
        </div>
        </div>
        </div>
      </div>
    </section>
    </section>
    </>
  )
}

export default SearchEngineOptimization
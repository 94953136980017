import React from 'react'
import { Col, Row } from 'reactstrap'

const TheSolution = () => {
    return (
        <div id='website-responsive' style={{ overflow:'hidden'}}>
            <div className='left-padding8rem1 right-padding8rem1 py-lg-5 py-md-4 py-4' style={{ backgroundColor:'black', opacity:0.85}}>
                <div className=''>
                    <h3 className='fs-ss-36 text-ss-primary text-philos fw-700'>
                        The Solution
                    </h3>
                </div>
                <div className='mt-lg-4 mt-md-4 mt-3'>
                    <p className='text-white fs-ss-15 text-mulish fw-500'>
                        NetPLUS chose ZM Communication to develop and execute the perfect lead management system to boost its leads and website management. Using Customer relationship management (CRM) technology for managing NetPLUS’ improved services and interactions with current and potential users, ZM Communication provided NetPLUS with a system to stay connected to users, streamline processes, and improve profitability. The well-thought-out Customer relationship management (CRM), was developed with MERN Stack:
                    </p>
                </div>
                <div className='py-4'>
                    <Row className=''>
                        <Col xs='6' sm='6' md='6' lg='3' className='mb-4 mb-md-3 mb-lg-0'>
                            <div className='d-grid justify-content-center align-items-center'>
                            <center>
                                <div className=''>
                                    <img src='case-study/ZM-React-v1-150x150.png' alt='' width='70px' className='' />
                                </div>
                                <div className='mt-3'>
                                    <p className='text-white fs-ss-15 text-mulish fw-500'>
                                        React.js
                                    </p>
                                </div>
                                </center>
                            </div>
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='3' className='mb-4 mb-md-3 mb-lg-0'>
                            <div className='d-grid justify-content-center align-items-center'>
                            <center>
                                <div className=''>
                                    <img src='case-study/ZM-Node-JS-v1.png' alt='' width='100px' className='' />
                                </div>
                                <div className='mt-3'>
                                    <p className='text-white fs-ss-15 text-mulish fw-500'>
                                        Node.js
                                    </p>
                                </div>
                                </center>
                            </div>
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='3' className='mb-4 mb-md-3 mb-lg-0'>
                            <div className='d-grid justify-content-center align-items-center'>
                            <center>
                                <div className=''>
                                    <img src='case-study/ZM-Express-JS-v1.png' alt='' width='145px' className='' />
                                </div>
                                <div className='mt-3'>
                                    <p className='text-white fs-ss-15 text-mulish fw-500'>
                                        Express.js
                                    </p>
                                </div>
                                </center>
                            </div>
                        </Col>
                        <Col xs='6' sm='6' md='6' lg='3' className='mb-4 mb-md-3 mb-lg-0'>
                            <div className='d-grid justify-content-center align-items-center'>
                            <center>
                                <div className=''>
                                    <img src='case-study/ZM-Mongo-DB-v1.png' alt='' width='145px' className='' />
                                </div>
                                <div className='mt-3'>
                                    <p className='text-white fs-ss-15 text-mulish fw-500'>
                                        MongoDB
                                    </p>
                                </div>
                                </center>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className=''>
                    <p className='text-white fs-ss-15 text-mulish fw-500'>
                        It enabled the staff manager to log in to the CRM as super admin and manage the lead inquiries, contact inquiries, professors, courses, along with activities, emailing and course calendar for impactful results.
                    </p>
                </div>
                <div className=''>
                    <p className='text-white fs-ss-15 text-mulish fw-500'>
                        For a more aesthetically and technically enhanced website the website was developed on React.js. The visual and functional appeal was improved with quality graphics and mobile-optimized design that reduced the bounce rate successfully.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TheSolution
import React from 'react'
import { DropdownItem, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Footer from './Footer';
import { Facebook, Instagram, Linkedin, Pinterest, Twitter, Youtube } from 'react-bootstrap-icons';
import HeaderLogo from '../assets/images/HeaderLogo.png'

const Header2 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const handleAboutClick = (e) => {
        navigate('/about-us', )
    }
    return (
        <>
            <Navbar expand="xl" className="px-md-4 px-3 py-0 bg-white" id="website-nav" style={{ display: 'block', overflow: '', borderBottom: '' }}>
                <div className='px-md5 px-lg4 left-margin-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >
                    <Navbar.Brand ><Link
                        className="flexwrap navbar-brand d-flex align-items-baseline"
                        to="/"
                    >
                        <img
                            src={HeaderLogo}
                            alt="Logo"
                            className="py2 img-fluid logoimg header-logo logo-setting"
                        />
                    </Link>
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-xl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                        placement="start"
                        className="navbar-offcanvas"
                    >
                        <Offcanvas.Header closeButton>

                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                <Link
                                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                                    to="/"
                                >
                                    <img
                                        src={HeaderLogo}
                                        alt="Logo"
                                        className="py-2 img-fluid header-logo logo-setting"
                                        style={{ height: "auto" }}
                                    />
                                </Link>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        {/* <hr /> */}
                        <Offcanvas.Body className='ps-lg-5'>
                            <Nav className="justify-content-end flex-grow-1 ps5 text-montserrat fw-600 website-np align-items-nav">
                                <NavDropdown renderMenuOnMount={true} onToggle={(e)=>handleAboutClick(e)} title='ABOUT US'   className={splitLocation[1] === "about-us" ? "fw-bold text-ss-primary sub-nav-menu  header-res py2 fs-ss-15-header nav-link text-lato fw-600  textuppercase pe-" : "fw-bold pe- py2 header-res sub-nav-menu fs-ss-15-header nav-link text-lato fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "500", fontSize: '' }}>

                                    <Link to="/portfolio" className={splitLocation[1] === "portfolio" ? "active py2 header-res fs-ss-16 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-16 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600", fontSize: '13px' }}>Portfolio</Link>

                                    <Link to="/faqs" className={splitLocation[1] === "faqs" ? "active py2 header-res fs-ss-16 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-16 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600", fontSize: '13px' }}>FAQs</Link>

                                    <Link to="/netplus-college" className={splitLocation[1] === "netplus-college" ? "active py2 header-res fs-ss-16 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-16 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize: '13px' }}>Case Study NetPlus College</Link>

                                </NavDropdown>

                                <NavDropdown renderMenuOnMount={true} title='SERVICES' to="/services" className={splitLocation[1] === "services" ? "fw-bold active sub-nav-menu header-res py2 fs-ss-15-header nav-link text-lato fw-600 text-hover-blue textuppercase pe-" : "fw-bold pe- py2 sub-nav-menu header-res fs-ss-15-header nav-link text-lato fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "500", fontSize: '' }}>

                                    <Link to="/services/branding/" className={splitLocation[1] === "services/branding/" ? "active py2 header-res fs-ss-16 text-ss-primary nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>Branding</Link>

                                    <Link to="/services/graphic-designing" className={splitLocation[1] === "services/graphic-designing" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>Graphic Designing</Link>

                                    <Link to="/services/content-writing/" className={splitLocation[1] === "services/content-writing/" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>Content Writing</Link>

                                    <Link to="/app-development/" className={splitLocation[1] === "/app-development/" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>App Development</Link>

                                    <Link to="/services/web-development/" className={splitLocation[1] === "services/web-development/" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>Web Development</Link>

                                    <Link to="/services/search-engine-optimization/" className={splitLocation[1] === "services/search-engine-optimization/" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>Search Engine Optimization</Link>

                                    <Link to="/services/social-media-marketing/" className={splitLocation[1] === "services/social-media-marketing/" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>Social Media Marketing</Link>

                                    <Link to="/services/video-animation/" className={splitLocation[1] === "services/video-animation/" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>Video Animation</Link>

                                    <Link to="/services/ppc-marketing/" className={splitLocation[1] === "services/ppc-marketing/" ? "active py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase pe-" : "pe- py2 header-res fs-ss-14 nav-link text-montserrat fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "600",fontSize:'13px' }}>PPC Marketing</Link>
                                </NavDropdown>

                                <Link to="/packages" className={splitLocation[1] === "packages" ? "fw-bold active  py2 fs-ss-15-header header-res nav-link text-lato fw-600 text-hover-blue textuppercase pe-" : "fw-bold pe- py2 header-res fs-ss-15-header nav-link text-lato fw-600 text-hover-blue textuppercase"} style={{ fontWeight: "500", fontSize: '' }}>PACKAGES</Link>

                                <Link to="/services/healthcare-solutions" className={splitLocation[2] === "healthcare-solutions" ? "fw-bold active  py2 fs-ss-15-header header-res nav-link text-lato fw-600 text-hover-blue" : "fw-bold pe- py2 fs-ss-15-header header-res nav-link text-lato fw-600 text-hover-blue"} style={{ fontWeight: "500", textDecoration:'none', backgroundColor: '', fontSize: '', padding: '' }}>HEALTHCARE SOLUTIONS</Link>

                                <Link to="/contact" className={splitLocation[1] === "contact" ? "fw-bold active  py2 fs-ss-15-header header-res nav-link text-lato fw-600 text-hover-blue" : "fw-bold py2 fs-ss-15-header header-res nav-link text-lato fw-600 text-hover-blue"} style={{ fontWeight: "500", fontSize: '', padding: '' }}>CONTACT</Link>

                                <Link to="/blogs" className={splitLocation[1] === "blogs" ? "fw-bold active  py2 fs-ss-15-header header-res nav-link text-lato fw-600 text-hover-blue" : "fw-bold py2 fs-ss-15-header nav-link header-res text-lato fw-600 text-hover-blue"} style={{ fontWeight: "500", fontSize: '', padding: '' }}>BLOG</Link>

                                <div className='d-flex justify-content-center align-items-center ms-lg-5 mt-4 mt-md-0'>
                                    
                                    <div className='me-3 fs-ss-14'>
                                        <a className='text-black text-decoration-none' href="https://www.facebook.com/zmcomm" target='_blank'>
                                        <Facebook/>
                                        </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-black text-decoration-none' href="https://www.instagram.com/zmcommunication_org/" target='_blank'>
                                        <Instagram/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-black text-decoration-none' href="https://twitter.com/ZMCOMM" target='_blank'>
                                        <Twitter/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-black text-decoration-none' href="https://www.linkedin.com/company/zm-communication/about/" target='_blank'>
                                        <Linkedin/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-black text-decoration-none' href="https://www.pinterest.co.uk/Zmcommg/" target='_blank'>
                                        <Pinterest/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-black text-decoration-none' href="https://www.youtube.com/channel/UCF92-mO-441yQpSfFLaDihw" target='_blank'>
                                        <Youtube/>
                                    </a>
                                    </div>

                                </div>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </div>
            </Navbar>
            </>
    )
}

export default Header2
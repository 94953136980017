import React from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
}

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '200px'
}

const slideImages = [
    {
        url: 'home/EHR.png',
        width:'200px'
        // caption: 'Slide 1'
    },
    {
        url: 'home/Ihsan-Coaching.png',
        width:'200px'
        // caption: 'Slide 2'
    },
    {
        url: 'home/Premier-Star-500-x-500-1.png',
        width:'200px'
        // caption: 'Slide 3'
    },
    {
        url: 'home/Premier-Builders-logo-500x500-2.png',
        width:'200px'
        // caption: 'Slide 3'
    },
    {
        url: 'home/GM-500-x-500-1.png',
        width:'200px'
        // caption: 'Slide 3'
    },
];

const OurClients = () => {
    return (
        <div id='website-responsive'>
            <div className='left-padding10rem1 right-padding10rem1 py-md-5 py-4'>
                <div className='pb-md-4 pb-3' style={{ color: '#FE9B1F' }}>
                    <center>
                        <div className='fs-ss-38 text-ss-primary text-philos fw-700' >
                            Our Clients
                        </div>
                    </center>
                </div>

                <div className=''>
                    <div className="slide-container">
                        <Slide nextArrow={<button  style={{ display:'none'}}></button>} prevArrow={<button style={{ display:'none'}}></button>} slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} responsive={[{
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        }, {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        }]}>
                            {slideImages.map((slideImage, index) => (
                                <div key={index}>
                                    <div style={{ ...divStyle,   }}>
                                        {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                                        <div className=''>
                                            <img src={slideImage.url} alt='' width='220px'  />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slide>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurClients
import React from 'react'
import { Col, Row } from 'reactstrap'

const TheChallenge = () => {
    return (
        <div id='website-responsive' style={{ overflow:'hidden'}}>
            <div className='left-padding8rem1 right-padding8rem1 py-lg-5 py-md-4 py-4'>
                <Row className=''>
                    <Col lg='2' className=''>
                        <div className='display-none'>
                            <img src='case-study/NetPlus-Case-Study-ZM-icon-04.png' alt='' className='' />
                        </div>
                    </Col>
                    <Col lg='10' className=''>
                        <div className=''>
                            <h3 className='fs-ss-36 text-philos fw-800 text-ss-primary'>
                                The Challenge
                            </h3>
                        </div>
                        <div className='mt-lg-3'>
                            <p className='fs-ss-15 text-mulish fw-500 text-ss lh-1-6'>
                                <strong>NetPLUS College</strong> of Informational Technology is a registered private career college recognized across Canada and worldwide for helping students begin new careers since 1999. With an approach to “bring the real world into the classroom,” NetPLUS has been employing top industry professionals with extensive backgrounds in Cisco, Microsoft, VMWare, and Palo Alto.
                            </p>
                        </div>
                        <div className=''>
                            <p className='fs-ss-15 text-mulish fw-500 text-ss lh-1-6'>
                                When NetPLUS College sought out the help of ZM Communication, they found themselves looking for ways to manage their dynamic assets smoothly and efficiently for improved lead and system management. Another objective was to increase their student reach within a demographic of young, passionate millennials looking for stable career pathways.
                            </p>
                        </div>
                        <div className=''>
                            <p className='fs-ss-15 text-mulish fw-500 text-ss lh-1-6'>
                                There were three basic challenges which needed to be tackled:
                            </p>
                        </div>

                        <div className='d-md-flex d-xs-grid align-items-center right-padding8rem1'>
                            <div className='me-4'>
                                <img src='case-study/NetPlus-Case-Study-ZM-icon-01.png' alt='' className='' width='60px' />
                            </div>
                            <div className=''>
                                <p className='fs-ss-15 text-mulish fw-500 text-ss lh-1-6'>
                                The website wasn't up to the mark, nor was their Lead Management System. All essential data had to be uploaded manually.
                                </p>
                            </div>
                        </div>
                        <div className='d-md-flex d-xs-grid align-items-center right-padding8rem1'>
                            <div className='me-4'>
                                <img src='case-study/NetPlus-Case-Study-ZM-icon-02.png' alt='' className='' width='60px' />
                            </div>
                            <div className=''>
                                <p className='fs-ss-15 text-mulish fw-500 text-ss lh-1-6'>
                                The previous system was outdated; it was inherently slow, which was accredited to the high bounce rate.
                                </p>
                            </div>
                        </div>
                        <div className='d-md-flex d-xs-grid align-items-center right-padding8rem1'>
                            <div className='me-4'>
                                <img src='case-study/NetPlus-Case-Study-ZM-icon-03.png' alt='' className='' width='60px' />
                            </div>
                            <div className=''>
                                <p className='fs-ss-15 text-mulish fw-500 text-ss lh-1-6'>
                                The website design was quite simple compared to the current design trends; it had failed to keep up not just aesthetically but technologically.
                                </p>
                            </div>
                        </div>

                        <div className='mt-lg-4 mt-3'>
                            <p className='fs-ss-15 text-mulish fw-500 text-ss lh-1-6'>
                            Capitalizing on their proven record of accomplishment of providing High-Tech training with programs that provide all the necessary skills for gaining employment in Canada and abroad, NetPLUS College wanted to streamline their lead management significantly.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default TheChallenge
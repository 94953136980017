import React from 'react'
import { Col, Row } from 'reactstrap'

const OurVision = () => {
  return (
    <div id='website-responsive' style={{ overflow:'hidden'}}>
    <div className='left-padding8rem1 rightpadding8rem1 py-md- pt-4'>
        <Row className=''>
            <Col sm='12' md='12' lg='5' className='mt-lg-5'>
                <div className='memd-5'>
                    <div className='memd-5'>
                        <h3 className='text-philos fs-ss-38 text-ss-black fw-800'>
                            Our Vision
                        </h3>
                    </div>
                    <div className='mt-lg-4 mt-md-3 me-md-5 me-lg-0'>
                        <h3 className='text-mulish fs-ss-26-1 text-ss-black fw-500 lh-1-4' style={{ fontStyle:'italic'}}>
                            Humility keeps you authentic, and authenticity is an important trait.
                        </h3>
                    </div>
                    <div className='pt-md-3 pt-3 me-md-4'>
                        <p className='fs-ss-18 text-mulish fw-500 lh-1-8 '>
                        We believe in originality and authenticity in a world where everyone wants to hide behind masks. We want to provide digital marketing strategies and guidance to all and bring the best brands to the forefront with engaging solutions.
                        </p>
                    </div>
                    <div className='pt-md-3 pt-3 me-md-4'>
                        <p className='fs-ss-18 text-mulish fw-500 lh-1-8 '>
                        No matter how far the destination might be, we clear away all the blockades and make your journey with us worth remembering.
                        </p>
                    </div>
                </div>
            </Col>

            <Col sm='12' md='12' lg='7' className='mt-md-0'>
                <div className='mt-lg-5 mt-md-3 me-4 me-lg-0'>
                    <img src='about/Our-vision-2-1.jpg' alt='Digital marketing agency in Canada' className='img-home-1' height='' width='' />
                </div>
            </Col>
        </Row>

        <div className='mt-md-5 mt-4 me-4 me-md-0'>
        <h3 className='text-philos fs-ss-38 text-ss-black fw-800'>
            <center>
                Digital marketing agency in Canada
            </center>
        </h3>
        </div>
        
        
    </div>

    <div className='left-padding8rem1 right-padding8rem1 pb-md-5 pb-4' style={{ textAlign:'justify'}}>
        <p className='fs-ss-18 text-mulish fw-500 lh-1-8' style={{ textAlign:'justify'}}>
            <center>
            As a creative digital marketing agency in Canada, our mission is to raise brand awareness among your target audience and enhance revenue while assisting you in locating clarity in the world of chaos. We seamlessly and efficiently push you to the next business level through our skilled digital marketing strategies.  
            </center>
        </p>
        </div>
</div>
  )
}

export default OurVision
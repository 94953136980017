import React from 'react'

const MiddleBannerSecondary = (props) => {
  return (
  <>
  <section id='website-responsive' className="container-fluid py-5 bg-ss-secondary text-center">
    <div className="row justify-content-center">
      <div className="col-11 col-lg-8">
    <div className="fs-ss-35 fw-800 text-ss-primary text-philos">
        {props.Title}
        </div>
        <div className='pt-3 pt-lg-4'/>
        <div className="fs-ss-16 fw-500 text-mulish lh-lg text-white">
        {props.Para}
        </div>
        </div>
    </div>
  </section>
  </>
  )
}

export default MiddleBannerSecondary
import React, { useEffect } from 'react'
import Header2 from '../../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import ServicesBanner from '../../components/haseeb/ServicesBanner'
import BannerImg from '../../assets/images/bannners/VideoAnimation.png'
import Img1 from '../../assets/images/video-animation/Img1.gif'
import Gif1 from '../../assets/images/video-animation/Gif1.gif'
import Animation from '../../assets/images/video-animation/ZM-Animation.gif'
import MiddleBannerPrimary from '../../components/haseeb/MiddleBannerPrimary'
import MiddleBannerSecondary from '../../components/haseeb/MiddleBannerSecondary'
import { Col, Row } from 'reactstrap'
import { FaDotCircle } from 'react-icons/fa'

const VideoAnimation = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <ServicesBanner
    BannerImg={BannerImg}
    SubTitle="Run Your Own Show"
    Title="Video Animation"
    />
<section id='website-responsive' className="container-fluid px-0">
<div id='website-responsive'>
        <div className='container-fluid ps-lg-5 ps-4 py-lg-5 py-4'>
            <Row className=''>
                <Col lg='3' className=''>
                    <div className='pb-md-3 pb-lg-0 pb-3'>
                        <div className='d-flex align-items-center'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/healthcare-solutions' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Healthcare Solutions
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/branding/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Branding
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/graphic-designing' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Graphic Designing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/content-writing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Content Writing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/web-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Web Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/app-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    App Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/search-engine-optimization/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Search Engine Optimization
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                            <Link to='/services/social-media-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Social Media Marketing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/video-animation/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-24 text-mulish fw-500 text-ss-primary'>
                                Video Animation
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/ppc-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                PPC Marketing
                            </span>
                                </Link>
                        </div>
                    </div>
                </Col>

                <Col className='ps-lg-4 d-none d-lg-block'>
                        <div className='d-block d-lg-flex align-items-start'>
                          <div className='w-75'>
                          <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Streamline Your Process with Video Production Consulting
                            </h2>
                        </div>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            Do you want to make your videos even better? We can help with something called Video Production Consulting. We at <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication </Link> have experts who know a lot about making videos, and they can help you at every step. From coming up with ideas and writing scripts to filming and producing videos that look great, we'll give you exceptional advice. We want to ensure your videos are exciting and say what you want. We've done this often to improve your videos and <Link className='text-decoration-none text-ss-primary' to="/services/ppc-marketing/">achieve the desired results.</Link> It prioritizes the needs of its consumers and creates public-friendly animated videos that appeal to the target market and assist clients in increasing their income.


                            </p>
                            </div>
                            <div className='px-lg-5'>
                            <img className='' src={Animation} alt="Animation" style={{width:"400px"}} />
                        </div>
                    </div>
                </Col>

                <Col className='d-block d-lg-none'>
                          <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Streamline Your Process with Video Production Consulting
                            </h2>
                        </div>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            Do you want to make your videos even better? We can help with something called Video Production Consulting. We at <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication </Link> have experts who know a lot about making videos, and they can help you at every step. From coming up with ideas and writing scripts to filming and producing videos that look great, we'll give you exceptional advice. We want to ensure your videos are exciting and say what you want. We've done this often to improve your videos and <Link className='text-decoration-none text-ss-primary' to="/services/ppc-marketing/">achieve the desired results.</Link> It prioritizes the needs of its consumers and creates public-friendly animated videos that appeal to the target market and assist clients in increasing their income.
                            </p>
                            <div className='px-lg-5'>
                            <img className='w-100' src={Animation} alt="Animation" />
                    </div>
                </Col>
            </Row>
        </div>
    </div>

    <section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Transform Your Message: High-Quality Corporate Video Production Services
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Make your brand look even better with our Corporate Video Production Services. Our team of experts is good at making high-quality videos that help you tell your story to the people you want to reach. We'll help you from the beginning, from <Link className='text-decoration-none text-ss-primary' to="/services/content-writing/"> developing ideas and writing scripts </Link> to making the videos and adding special effects. We want to make sure your videos look cool and show off your products, services, and what your company is like. Our Corporate Video Production Services will help people get excited <Link className='text-decoration-none text-ss-primary' to="/services/branding/"> your business and make it grow. </Link> At ZM Communication, we make awesome 2D and 3D corporate videos. Our videos help your business grow by attracting more customers and boosting sales. Think of it like this: our videos are worth even more if a picture is worth a thousand words!
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerPrimary
    Title="Video marketing for brands and businesses"
    Para="This strategy can help you use video ads in several creative ways because it can do far more than promote your product or service, like building customer rapport, generating interest in your brand vision, and showcasing customer testimonials. When used alongside social media, video marketing can even be used for live-stream events, how-to instructional videos, educational videos, and much more."
    />
    
    <section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-center gy-4'>
        <div className="col-11 col-md-5">
          <img className='w-100' src={Gif1} alt="" />
        </div>
        <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Animated Explainer Videos
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Would you like to excite your target market about a product or service you offer?? Explainer videos are an excellent method to communicate even the most routine topics visually. As the majority prefer watching over reading, a video quickly reaches nearly every industry and demographic. Because animated explainer videos are engaging in every way, it is unimaginably effective in creating a solid connection with your current and potential customers and spiking purchases.            
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerSecondary
    Title="Visual Effects, 2D and 3D Animation"
    Para="Animations are always good to provide up-to-date information to your customers. Several of our projects include 2D and 3D Animation to create a product description or bring an entire scene to life. We offer a free consultation to our clients, including professional video animation services in Canada. We ensure to have eye-catching material such as icons, text, characters in our animated videos to keep clients' attention forever intact. Moreover, through video animation, you can convey your message to the right audience in a captivating way."
    />
    
    <section className="container-fluid pt-4 pt-lg-5">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Motion Graphic Animation
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Motion graphic animation is a fun technique to reach out to your audience. It is made up of letters and digital pictures and provides the impression of movement or rotation. We at Z.M. Communication provide Motion Graphics animation services in Canada in the most appealing way possible to keep your audience’s spellbound and mesmerized. Our motion graphic services can transform dull information into a magical and entertaining experience. We develop Motion Graphic films that uniquely connect with clients and convey their stories effectively by combining the most relevant graphics with narration. Our goal is to arrange such films that the viewers can’t help but watch them. As a result, they’ll buy what you’re selling.
             </p>
        </div>
        </div>
      </div>
    </section>

    <section className="container-fluid pb-5">
    <div className="row justify-content-center gy-4">
      <div className="col-11 col-lg-5 border-style-right">
        <div className='pt-lg-5 pt-4 ps-lg-3 pe-lg-5'>
        <div className="fs-ss-30 fw-800 text-ss-primary text-philos">
        Whiteboard Animation Services
        </div>
        <div className="fs-ss-16 fw-500 text-mulish lh-lg text-black fst-bold">
        Whiteboard animation is produced by drawing static <Link className='text-ss-primary text-decoration-none' to="/services/graphic-designing/"> graphics </Link> and stands as one of the essential explainer movies in the animation business. Many known brands worldwide use this video style to sell their products. Z.M. Communication caters to brands, provides them with compelling whiteboard movies, and is committed to effectively creating and delivering a definite and tailored brand message leaving an impact that what they do is beyond the grasp of all forms of audiences.
        </div>
        </div>
      </div>
      <div className="col-11 col-lg-5 position-border-1 shadow-gif">
        <img src={Img1} alt="" className="w-100" />
      </div>
    </div>
  </section>
  
  <section className="container-fluid pt-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Channelizing the Power of Impactful Video Marketing
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             It might seem implausible, but the best way to create viral content is to emphasize connecting emotionally with the consumer when it comes to online marketing. It has been theorized that viral video marketing is effective because it sparks ideas and captures the imagination. Plus, if your competitor is doing it, you do it too. Moreover, many people prefer to watch animation videos rather than reading the content, so if you add short videos to your website, it would be a great addition and valuable technique. Another effective strategy you can follow is adding a short 2d and 3d video to your website and the text to provide the information appealingly and creatively. You should add motion or graphic animations to your website and <Link className='text-decoration-none text-ss-primary' to="/services/social-media-marketing/"> social media </Link>accounts to solidify your online presence. Furthermore, you can avail yourself of video animation services in Canada for giving strong visualization for your brand or business.              
             </p>
        </div>
        </div>
      </div>
    </section>

    <section className="container-fluid pt-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Viral Video Marketing #Booming Business
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Want to chalk out an effective video marketing strategy? Z.M. Communication, a digital marketing agency in Canada, can help you in doing impactful planning include the following key elements:             
             <ul>
                <li>
                    <strong>
                    Technical Resources–
                    </strong>
                     With professional, result-oriented editors on your side, your brand will gain interest and generate sales in no time.
                </li>

                <li>
                    <strong>
                    A Good Story –
                    </strong>
                     We deliver stories that resonate with your target audience with a strong emphasis on your product or service.
                </li>

                <li>
                    <strong>
                    Strategic Perception –
                    </strong>
                     We work hard to understand your brand’s mission and consumer insights to weave our stories around them.
                </li>

                <li>
                    <strong>
                    Engagement Spot on –
                    </strong>
                     Simple and ordinary content just won’t do. The video and content must hook your audience and make them want to buy your product or request the service.
                </li>

                <li>
                    <strong>
                    Every Second Counts –
                    </strong>
                     The shorter, the better, is the empowerment rule. Video marketing is here to compete with every other marketing medium out there, so we keep it brief and catchy.
                </li>
             </ul>
             </p>
        </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             People don’t buy what you sell; they buy what you SHOW you sell
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             At Z.M. Communication, we meet your requirements and know how to present your brand image in the best possible perspective. High-quality production, meticulous attention to detail, and a compelling tale are just a few of the features that distinguish our animation services. Whether you need storyboarding, or 3D Animation, mixed-media animation, or creative editorials, you can count on us to deliver through our best animation services. If you’re looking to develop and create world-class branded video content, contact us to reach a bigger audience and create an everlasting brand identity.             
             </p>
        </div>
        </div>
      </div>
    </section>
    </section>
    </>
  )
}

export default VideoAnimation
import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ContactMain from '../components/contact/ContactMain'
import Info from '../components/contact/Info'

const ContactPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <div style={{ overflow: 'hidden', backgroundImage: 'url("/bg-img/MicrosoftTeams-image.png")', width: '100%', position: '', backgroundAttachment: 'fixed' }}>
      

      <ContactMain />
      <Info />
    </div>
    </>
  )
}

export default ContactPage
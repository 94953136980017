import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Testimonials from '../components/home/Testimonials';
import ServicesBanner from '../components/haseeb/ServicesBanner';
import BannerImg from '../assets/images/testimonials/BannerImage.png';

const TestimonialsPage = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
        return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
            <ServicesBanner
    BannerImg={BannerImg}
    SubTitle="Our Top Priority"
    Title="Client Testimonials"
    />
        <Testimonials/>
        </>
    )
}

export default TestimonialsPage
import React from 'react'

const TermsConditions = () => {
    return (
        <div id='website-responsive'>
            <div className='m-5-1' style={{ backgroundColor:'#FFFFFF'}}>
            <div className='left-padding5rem1 right-padding5rem1 py-lg-5 py-4' style={{ backgroundColor:'#FFFFFF'}}>
                <div className='pt-lg-4 pt-3 pb-3'>
                    <h1 className='fs-ss-40-1 text-lato text-ss-black fw-700'>
                        <center>
                            Terms & Conditions
                        </center>
                    </h1>
                </div>

                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-32 text-lato fw-700 text-ss-black'>
                            Graphic Design Services
                        </h2>
                    </div>
                    <ul className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>Z.M. Communication retains the right to use work produced for the client as part of its portfolio materials in its online and offline portfolios.
                        </li>
                        <li className='mb-1'>Any additional work, revisions, or variations outside of the original Visual Brief will be charged at CAD 120 per hour, billed in 15-minute increments. All prices exclude GST. Additional expenses will be charged accordingly.
                        </li>
                        <li className='mb-1'>The client will appoint a single representative with full authority to provide the necessary information required by Z.M. Communication and handling approvals.
                        </li>
                        <li className='mb-1'>The client understands that Z.M. Communication product is collaborative. A typical project takes around 3-5 weeks to complete once we receive a completed creative brief. Based on the complexity or simplicity, the timeline can be increased or lessened.
                        </li>
                        <li className='mb-1'>All-time quotations are estimated based on calendar working (business) days.
                        </li>
                        <li className='mb-1'>We reserve the right to edit your video after the order is complete. An hourly fee will be associated with any additional work beyond the project’s original scope.
                        </li>
                        <li className='mb-1'>These Terms and Conditions supersede any previous Terms and Conditions distributed in any form. Z.M. Communication reserves the right to change any rates and any Terms and Conditions without prior notice.
                        </li>
                    </ul>
                </div>

                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-32 text-lato fw-700 text-ss-black'>
                            Web Development Services
                        </h2>
                    </div>
                    <ul className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>We need to know every client’s requirement. If we do not receive any requirements from the client, we will use our knowledge to understand the project goals and quote a price accordingly.
                        </li>

                        <li className='mb-1'>If there is a disagreement later, our client might pay extra for the modifications.

                        </li>
                        <li className='mb-1'>Delays caused by the client may result in extra expenses and time delays for the project.

                        </li>
                        <li className='mb-1'>Any re-work on a completed/approved project will attract additional charges.

                        </li>
                        <li className='mb-1'>The client should provide their name and hosting service. All projects and proposals exclude the cost of registering and hosting a domain name. However, we can quote our price for the hosting service.

                        </li>
                        <li className='mb-1'>There may be extra expenses for loading a finished website onto the third-party hosting services supplied by the client.

                        </li>
                        <li className='mb-1'>When using a file server or disk space, it is up to the client to make sure everything is set up correctly, as the client must agree on additional expenditures if needed to complete their project. Purchasing custom typefaces, a theme, or stock photos are a few examples.

                        </li>
                        <li className='mb-1'>At the bottom of the client’s website, a link to Simple Solutionz will display in tiny print or as a small image.

                        </li>
                    </ul>
                </div>

                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-32 text-lato fw-700 text-ss-black'>
                            Content Writing Services
                        </h2>
                    </div>
                    <ul className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>We provide SEO-optimized blog writing, article writing, scriptwriting, and website content services.

                        </li>

                        <li className='mb-1'>We would deliver the files in Microsoft Word or PDF format.


                        </li>
                        <li className='mb-1'>Our company will deliver your project within the agreed deadline, and we may change the delivery time with mutual understanding.


                        </li>
                        <li className='mb-1'>Our revision request time is 3-5 working days. For low competition keywords, it may take our SEO team 2-3 months to rank your site and 4-5 months for high competition keywords.


                        </li>

                    </ul>
                </div>

                <div className=''>
                    <div className='mb-3'>
                        <h3 className='fs-ss-28 text-lato text-ss-black fw-700'>
                            How it Works:
                        </h3>
                    </div>
                    <div className=''>
                        <p className='fs-ss-16 text-lato text-ss-black'>
                            We need details regarding your products/services, competitors, and business objectives. Then, by using our strategy, we will develop an SEO plan that best fits your business.
                        </p>
                    </div>
                </div>

                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-24 text-lato fw-700 text-ss-black'>
                            Blog Writing:
                        </h2>
                    </div>
                    <ul className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>We are offering research-based and SEO-focused blogs that will be written by professional and experienced writers.


                        </li>

                        <li className='mb-1'>Our team consists of writers that are comfortable with writing on every topic and are always available to entertain custom offers.



                        </li>
                        <li className='mb-1'>Share your idea now and let us start working on it!
                        </li>


                    </ul>
                </div>

                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-24 text-lato fw-700 text-ss-black'>
                            Article Writing:
                        </h2>
                    </div>
                    <ul className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>Our skillful team has a knack for writing from the client’s perspective. No matter which topic your industry belongs to, we have built strong content strategies that will be implemented in your article.
                        </li>

                        <li className='mb-1'>We can have kick-off meetings to discuss your requirements. Also, you will be guided with suggestions that can easily grow your business and rank your content.
                        </li>
                    </ul>
                </div>
               
                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-24 text-lato fw-700 text-ss-black'>
                            Creative Script Writing:
                        </h2>
                    </div>
                    <ul className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>We are here to ease your difficulty in guiding a 3D animator to develop an interesting and purposeful video with our easy-to-understand script. Our professional writers make sure to follow your organizational guidelines and develop an interesting video.
                        </li>

                        <li className='mb-1'>Our writers are professional enough to produce different ideas that align with your business goals. Trust us to run successful marketing campaigns by creating the best-animated videos.
                        </li>
                    </ul>
                </div>
                
                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-24 text-lato fw-700 text-ss-black'>
                            Social Media Marketing
                        </h2>
                    </div>
                    <ol className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>We will require information about your business and its services to provide relevant social media marketing services. This information must include: 
                        </li>
                        <ul className='mb-3'>
                            <li className=''>Logos</li>
                            <li className=''>Backgrounds</li>
                            <li className=''>Working Methods</li>
                            <li className=''>Advertisement Goals</li>
                            <li className=''>Images/Videos</li>
                            <li className=''>Quotes</li>
                            <li className=''>Sales Material</li>
                            <li className=''>Technical Specifications</li>
                        </ul>

                        <li className='mb-1'> All the written content including posts, tweets, chats and Blogs, will be published after the approval of clients.
                        </li>
                        <li className='mb-1'>We will provide 2 to 3 revisions for all the services.
                        </li>
                    </ol>
                </div>

                <div className='mb-3'>
                    <div className='my-3'>
                        <h2 className='fs-ss-24 text-lato fw-700 text-ss-black'>
                        Search Engine Optimization
                        </h2>
                    </div>
                    <ul className='fs-ss-16 text-lato text-ss-black'>
                        <li className='mb-1'>The package is aimed at providing monthly services that can build an innovative web platform for you in 3-6 months. In order to notice a measurable rank increase of easy keywords on your site, it can take at least 2-3 months. For ranking competitive keywords, a time of 4-5 months is required.

                        </li>

                        <li className='mb-1'>Before implementing our SEO strategy, we will require an in-depth knowledge of your products/services, competitors, business objectives, focused keywords, and goal customers.
                        </li>
                        <li className='mb-1'>We offer the implementation of best SEO practices that require weeks and months to show outputs. But, we can’t guarantee results.
                        </li>
                    </ul>
                </div>

                <div className=''>
                    <p className='fw-500 text-lato'>
                        <em className=''>
                            <strong>Note: </strong> We do not work on adult, gambling, and pharmacy sites.
                        </em>
                    </p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default TermsConditions
import React from 'react'
import {Link} from 'react-router-dom'

const Websites = () => {

    const websiteImg = [
        {
            name:'Join EHRB Website',
            img:'portfolio/website/EHRB-ZM-portfolio-web-v1.webp',
            link:"https://joinexecutivehomes.ca/"
        },
        {
            name:'Premier Builders Website',
            img:'portfolio/website/Permier-Builders-ZM-portfolio-web-v1.webp',
            link:"https://premiersbuilders.com/"
        },
        {
            name:'Ghulam Murtaza Realtor Website',
            img:'portfolio/website/Ghulam-Murtaza-ZM-portfolio-web-v1.webp',
            link:"https://murtaza.ca/"
        },
        {
            name:'Premier Star Group Website',
            img:'portfolio/website/Preimer-Star-Group-ZM-portfolio-web-v1.webp',
            link:"https://premierstargroup.com/"
        },
        {
            name:'NetPlus College Website',
            img:'portfolio/website/NetPlus-ZM-portfolio-web-v1.webp',
            link:"https://netpluscollege.com/"
        },
        {
            name:'Rizwood Website',
            img:'portfolio/website/RizWood-ZM-portfolio-web-v1.webp',
            link:"https://rizwood.com/"
        },
        {
            name:'Call Doctor Website',
            img:'portfolio/website/Call-Doctor-ZM-portfolio-web-v1.webp',
            link:"https://calldoctor.ae/"
        },
        {
            name:'My Family MD Website',
            img:'portfolio/website/My-FamilyMD-ZM-portfolio-web-v1.webp',
            link:"https://myfamilymd.org/"
        },
        {
            name:'US Travel Lab Website',
            img:'portfolio/website/US-Travel-Lab-ZM-portfolio-web-v1.webp',
            link:"https://ustravellab.com/"
        },
        {
            name:'Valley Pediatrics Website',
            img:'portfolio/website/Valley-Pediatrics-ZM-portfolio-web-v1.webp',
            link:"https://www.valley-pediatrics.com/"
        },
        {
            name:'United Power Resources Website',
            img:'portfolio/website/UPR-ZM-portfolio-web-v1.webp',
            link:"https://unitedpowerresources.com/"
        },
        {
            name:'United Covid Care Website',
            img:'portfolio/website/UCC-ZM-portfolio-web-v1.webp',
            link:"https://unitedlabcare.com/"
        },
        {
            name:'Ihsan Coaching Website',
            img:'portfolio/website/Ihsan-Coaching-ZM-portfolio-web-v1.webp',
            link:"https://ihsancoaching.com/"
        },
        {
            name:'IM Clinic Website',
            img:'portfolio/website/IM-Clinic-ZM-portfolio-web-v1.webp',
            link:"https://imclinic.org/"
        },
        {
            name:'HHD Outfits',
            img:'portfolio/website/HHD-Outfits-ZM-portfolio-web-v1.webp',
            link:"https://hhdoutfits.com/"
        },
        
    ]

  return (
    <div id='website-responsive'>
        <div className='py-4 px-md-4'>
            <div className='row justify-content-center'>
            
            {websiteImg.map((webimg,index) => (
                <div key={index} className='mb-4 w-30'>
                    <a className="" href={webimg.link} target='_blank'>                    
                    <div className='hover-img container-abs'>
                        <img src={`/${webimg.img}`} alt='dedw' className='w-100' style={{ position:'', display:'block'}} /> 
                        <div className='overlay' style={{ position:''}}>
                        {webimg.name}    
                        </div> 
                    </div>
                    </a>
                </div>
            ))}
            </div>
        </div>
    </div>
  )
}

export default Websites
import React from 'react'
import BannerImg from '../../assets/images/bannners/Contact.png'

const ContactMain = () => {
  return (
    <div id='website-responsive'>
    <div className='bg-img1 padding-about' style={{ backgroundImage: `url(${BannerImg})`, width: '100%', backgroundColor: 'rgba(0,0,0, 0.60)' }}>
        <h2 className='fs-ss-60 text-philos fw-800 text-white'>
            Get In Touch
        </h2>
    </div>
</div>
  )
}

export default ContactMain
import React from 'react'

const UnionOfTechnologyAndHealthcare = () => {
  return (
    <div>
        <div id='website-responsive'>
            <div className='py-md-4 py-4' style={{ backgroundColor: '#FE9B1F' }}>
                <div className="mt-md-4 px-sm-4 px-md-0">
                    <center>
                        <h4 className="fs-ss-36 text-philos fw-600 text-ss-black" style={{ letterSpacing: '1px' }}>
                            A Union of Technology and Healthcare
                        </h4>
                    </center>
                </div>

                <div className="left-padding10rem1 right-padding10rem1">
                    <div className='mb-4 mb-md-4 mt-md-4 mt-4'>
                        <center>
                            <p className='fs-ss-16 fw-500 text-mulish text-white' style={{ textAlign:''}}>
                            ZM Communication endeavors to make medical practice more accessible for healthcare practitioners by providing various services that enable your day-to-day operations to run more smoothly and efficiently. We provide you with a large platform to store all records for an extended period and to make things like scheduling appointments easy for patients.
                            </p>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UnionOfTechnologyAndHealthcare
import React from 'react'
import { ArrowRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

const WeAreZM = () => {
    return (
        <div id='website-responsive'>
            <div className='left-padding8rem1 right-padding8rem1 py-md-5 py-4'>
                <Row className=''>
                    <Col sm='12' md='12' lg='6' className=''>
                        <div className='memd-5'>
                            <div className='memd-5'>
                                <h3 className='text-philos fs-ss-38 text-ss-black fw-800'>
                                    We are ZM Communication
                                </h3>
                            </div>
                            <div className='pt-md-3 pt-2 me-md-4'>
                                <p className='fs-ss-18 text-mulish fw-500 lh-1-8 '>
                                ZM communication is a multidisciplinary creative digital marketing agency in Canada. We took to the winds in 2005, and since then, every flight has been epic. With technology at our core, we work together to design, create, strategize, and produce work that we are excited about for global brands and emerging businesses. So, what makes us so unique is the matchless team of empaths we have. The jaw-dropping web designs, the breath snatching content, professional digital marketing strategies, and addictively powerful SEO pave our roads, making success not possible but happenable.
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col sm='12' md='12' lg='6' className='mt-md-3'>
                        <div className='mt-md-5'>
                            <img src='about/About-us-.jpg' alt='Digital marketing agency in Canada' className='img-home-1' height='' width='' />
                        </div>
                    </Col>
                </Row>

                <div className='mt-md-5 mt-4'>
                <h3 className='text-philos fs-ss-38 text-ss-black fw-800'>
                    <center>
                        Our Values
                    </center>
                </h3>
                </div>
                
                <div className='pt-md-3 pt-' style={{ textAlign:'justify'}}>
                <p className='fs-ss-18 text-mulish fw-500 lh-1-8' style={{ textAlign:'justify'}}>
                    <center>
                        As we assist our customers in growing their businesses, we strive to create transparent, long-term partnerships. Our customers come first, and we enlighten them about efficient digital marketing strategies and digital sales solutions that will provide a significant return on investment.
                    </center>
                </p>
                </div>
            </div>
        </div>
    )
}

export default WeAreZM
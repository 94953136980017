import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import TheChallenge from '../components/case-study/TheChallenge'
import TheSolution from '../components/case-study/TheSolution'
import Results from '../components/case-study/Results'
import MainBanner from '../components/case-study/MainBanner'

const CaseStudy = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <div  style={{ overflow:'hidden', backgroundImage:'url("bg-img/MicrosoftTeams-image.png")', width:'100%', position:'', backgroundAttachment:'fixed'}}>
        <MainBanner/>
        <TheChallenge/>
        <TheSolution/>
        <Results/>
    </div>
    </>
  )
}

export default CaseStudy
import React from 'react'
import { Col, Row } from 'reactstrap'

const SocialMediaPosts = () => {
    
    const socialMedia = [
        {
            name:'EHRB Social Media',
            img:'portfolio/social-media/EHRB-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/ExecutiveHomesRealty"            
        },
        {
            name:'Call Doctor Social Media',
            img:'portfolio/social-media/Call-Doctor-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/calldoctordxb/"            
        },
        {
            name:'Ihsan Coaching Social Media',
            img:'portfolio/social-media/Ihsan-Coaching-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/IhsanCoaching/"            
        },
        {
            name:'UPR Social Media',
            img:'portfolio/social-media/UPR-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/unitedpowerresources"            
        },
        {
            name:'Valley Pediatrics Social Media',
            img:'portfolio/social-media/Valley-Pediatrics-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/valleypediatrics/"            
        },
        {
            name:'UCC Social Media',
            img:'portfolio/social-media/UCC-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/unitedcovidcare"            
        },
        {
            name:'HHD Outfits Social Media',
            img:'portfolio/social-media/HHD-Outfits-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/hhdofficial"            
        },
        {
            name:'IM Clinic Social Media',
            img:'portfolio/social-media/IM-Clinic-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/theIMClinic/"            
        },
        {
            name:'IM Clinic Social Media',
            img:'portfolio/social-media/IM-Clinic-ZM-portfolio-social-media-v1.jpg',
            link:"https://www.facebook.com/theIMClinic/"            
        },

    ]

  return (
    <div id='website-responsive'>
        <div className='py-4 px-md-4'>
            <div className='row justify-content-center'>
            
            {socialMedia.map((social,index) => (
                <div key={index} className='mb-4 w-30'>
                    <a className="" href={social.link} target='_blank'>                    
                    <div className='hover-img container-abs'>
                        <img src={`/${social.img}`} alt='dedw' className='w-100' style={{ position:'', display:'block'}} /> 
                        <div className='overlay' style={{ position:''}}>
                        {social.name}    
                        </div> 
                    </div>
                    </a>
                </div>
            ))}
            </div>
        </div>
    </div>
  )
}

export default SocialMediaPosts
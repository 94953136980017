import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '../assets/css/style.css'
import HomePage from '../pages/HomePage'
import AboutPage from '../pages/AboutPage'
import CaseStudy from '../pages/CaseStudy'
import FaqsPage from '../pages/FaqsPage'
import DigitalMarketingPage from '../pages/DigitalMarketingPage'
import PortfolioPage from '../pages/PortfolioPage'
import PackagesPage from '../pages/PackagesPage'
import HealthcareSolutionPage from '../pages/HealthcareSolutionPage'
import PackagesForm from '../components/packages/PackagesForm'
import ContactPage from '../pages/ContactPage'
import TermsConditionspage from '../pages/TermsConditionspage'
import BlogsPage from '../pages/BlogsPage'
import ServicePage from '../pages/ServicePage'
import Branding from '../pages/servicespages/Branding'
import GraphicDesigning from '../pages/servicespages/GraphicDesigning'
import ContentWriting from '../pages/servicespages/ContentWriting'
import AppDevelopment from '../pages/servicespages/AppDevelopment'
import WebDevelopment from '../pages/servicespages/WebDevelopment'
import SearchEngineOptimization from '../pages/servicespages/SearchEngineOptimization'
import SocialMediaMarketing from '../pages/servicespages/SocialMediaMarketing'
import VideoAnimation from '../pages/servicespages/VideoAnimation'
import PPCMarketing from '../pages/servicespages/PPCMarketing'
import TestimonialsPage from '../pages/TestimonialsPage'
import ReferralProgram from '../pages/ReferralProgram'

const Header = React.lazy(() => import('../layout/Header'))

const Path = () => {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Header/>}>
                    <Route index element={<HomePage/>} />
                    <Route path='/about-us' element={<AboutPage/>} />
                    <Route path='/netplus-college' element={<CaseStudy/>} />
                    <Route path='/faqs' element={<FaqsPage/>} />
                    <Route path='/digital-marketing-agency-in-mississauga' element={<DigitalMarketingPage/>} />
                    <Route path='/portfolio' element={<PortfolioPage/>} />
                    <Route path='/packages' element={<PackagesPage/>} />
                    <Route path='/services/healthcare-solutions' element={<HealthcareSolutionPage/>} />
                    <Route path='/packages-form' element={<PackagesForm/>} />
                    <Route path='/contact' element={<ContactPage/>} />
                    <Route path='/terms-and-conditions' element={<TermsConditionspage/>} />
                    <Route path='/blogs' element={<BlogsPage/>} />
                    <Route path='/testimonials' element={<TestimonialsPage/>} />
                    <Route path='/referral-program' element={<ReferralProgram/>} />

                    {/* Services Pages */}
                    <Route path='/services/' element={<ServicePage/>} />
                    <Route path='/services/branding/' element={<Branding/>} />
                    <Route path='/services/graphic-designing' element={<GraphicDesigning/>} />
                    <Route path='/services/content-writing/' element={<ContentWriting/>} />
                    <Route path='/app-development/' element={<AppDevelopment/>} />
                    <Route path='/services/web-development/' element={<WebDevelopment/>} />
                    <Route path='/services/search-engine-optimization/' element={<SearchEngineOptimization/>} />
                    <Route path='/services/social-media-marketing/' element={<SocialMediaMarketing/>} />
                    <Route path='/services/video-animation/' element={<VideoAnimation/>} />
                    <Route path='/services/ppc-marketing/' element={<PPCMarketing/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  )
}

export default Path
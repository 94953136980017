import React from 'react'
// import { Quote } from 'react-bootstrap-icons'
import { FaQuoteRight } from "react-icons/fa";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
}

const divStyle = {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '420px',
    border:'1px solid #fe9b1f',
    marginRight:'1rem',
    marginLeft:'1rem',
    boxShadow:'2px 2px 10px 0px rgba(156.75, 105.53584484886358, 37.579534646739134, 0.5)',
}

const slideImages = [
    {
        url: 'home/EHR.png',
        width:'75px',
        content:"Z.M. Communication is one of the best digital marketing agencies we've worked with. We achieved incredible results thanks to their superb marketing services.",
        title:'Executive Home Reality'
        // caption: 'Slide 1'
    },
    // {
    //     url: 'home/Ihsan-Coaching.png',
    //     width:'100px',
    //     content:"We enjoyed Z.M. Communication's creativity and attention to detail throughout designing and creating our new website. Z.M. Communication helped us in developing new sources of revenue and improving advertising methods.",
    //     title:''
    //     // caption: 'Slide 2'
    // },
    {
        url: 'home/Premier-Star-Logo-1.png',
        width:'200px',
        content:"Z.M. Communication has excellent strategies and exceptional leadership qualities. When diligent people work together the results will always be awesome, and that is what Z.M. Communication has provided.",
        title:'Premier Star'
        // caption: 'Slide 3'
    },
    {
        url: 'home/Premier-Builders-Logo-testimonial.png',
        width:'80px',
        content:"We enjoyed Z.M. Communication's creativity and attention to detail throughout designing and creating our new website. Z.M. Communication helped us in developing new sources of revenue and improving advertising methods.",
        title:'Premier Builder'
        // caption: 'Slide 3'
    },
    {
        url: 'home/GM-logo.png',
        width:'220px',
        content:"I’ve worked with ZM Communication for years and find them always making every experience pleasant, effortless, and unique!  Thank you, Z.M Communication, for your professional digital marketing services.",
        title:'Ghulam Murtaza'
        // caption: 'Slide 3'
    },
];


const Testimonials = () => {
    return (
        <div id='website-responsive'>
            <div className='left-padding8rem1 right-padding8rem1 py-md-5 py-4'>
                <div className='pb-md-4 pb-3' style={{ color: '#FE9B1F' }}>
                    <center>
                        <h3 className='fs-ss-38 text-philos fw-700 text-ss-primary'>
                            Testimonials
                        </h3>
                    </center>
                </div>

                <div className=''>
                <div className="slide-container ">
                        <Slide easing='ease' nextArrow={<button  style={{ display:'none'}}></button>} prevArrow={<button style={{ display:'none'}}></button>} slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} responsive={[{
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        }, {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        }]}>
                            {slideImages.map((slideImage, index) => (
                                <div key={index} className=''>
                                    <div className='rounded10 p-md-4 p-3 ' style={{ ...divStyle,   }}>
                                        {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                                        {/* <img src={`${slideImage.url}`} alt='' width='100px' /> */}
                                        <div className=''>
                                            <FaQuoteRight className='fs-ss-52 text-ss-primary fw-800'/>
                                        </div>
                                        <center>
                                        <div className=''>
                                            <img src={slideImage.url} alt='' width={slideImage.width}  />
                                        </div>

                                        <div className='pxmd-3 my-3 my-md-4'>
                                            <p className='fs-ss-16 text-mulish fw-500'>
                                                {slideImage.content}
                                            </p>
                                        </div>

                                        <div className='mt-3' style={{ width:'10%', borderTop:'2px solid #fe9b1f' , color:'#fe9b1f'}} />

                                        <div className=' pt-md-4 mb-md-5 mb-4'>
                                        <p className='text-ss-primary text-philos fs-ss-20 fw-700'>
                                                {slideImage.title}
                                            </p>
                                        </div>
                                        </center>
                                    </div>
                                </div>
                            ))}
                        </Slide>
                    </div>
            </div>
            </div>
        </div>
    )
}

export default Testimonials
import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PortfolioBanner from '../components/portfolio/PortfolioBanner'
import PortfolioHeader from '../components/portfolio/PortfolioHeader'

const PortfolioPage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
        return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <div style={{ overflow: 'hidden', backgroundImage:'url("bg-img/MicrosoftTeams-image.png")', width:'100%', backgroundAttachment:'fixed' }}>

        <PortfolioBanner/>
        <PortfolioHeader/>
    </div>
    </>
  )
}

export default PortfolioPage
import React from 'react'

const ImageBorderStyleLeft = (props) => {
  return (
  <>
  <section className="container-fluid py-lg-5 pb-4">
    <div className="row justify-content-center gy-4">
      <div className="col-11 col-lg-5 border-style-right">
        <div className='pt-lg-5 ps-lg-3 pe-lg-5'>
        <div className="fs-ss-30 fw-800 text-ss-primary text-philos">
        {props.Title}
        </div>
        <div className="fs-ss-16 fw-500 text-mulish lh-lg text-black fst-bold">
        {props.Para}
        </div>
        </div>
      </div>
      <div className="col-11 col-lg-5 position-border-1">
        <img src={props.Img} alt="" className="w-100" />
      </div>
    </div>
  </section>
  </>
  )
}

export default ImageBorderStyleLeft
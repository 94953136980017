import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import AboutMain from '../components/about/AboutMain'
import WeAreZM from '../components/about/WeAreZM'
import CoreValues from '../components/about/CoreValues'
import WhatWeStandFor from '../components/about/WhatWeStandFor'
import OurVision from '../components/about/OurVision'

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <div style={{ overflow:'hidden', backgroundImage:'url("bg-img/MicrosoftTeams-image.png")', width:'100%', position:'', backgroundAttachment:'fixed'}}>
    <Header2/>
        <AboutMain/>
        <WeAreZM/>
        <CoreValues/>
        <WhatWeStandFor/>
        <OurVision/>
    </div>
    </>
  )
}

export default AboutPage
import React from 'react'

const Banner = (props) => {
  return (
  <>
  <section id='website-responsive' className="container-fluid bg-banner py-5 bg-banner-overlay" style={{backgroundImage:`url(${props.BannerImg})`}}>
    <div className="row justify-content-center py-lg-5 my-lg-5">
        <div className="col-10 pb-5">
        <div className='fs-ss-60 text-philos fw-800 text-white'>
            Our Services
        </div>
        </div>
    </div>
  </section>
  </>
  )
}

export default Banner
import React, { useEffect } from 'react'
import Header2 from '../../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ServicesBanner from '../../components/haseeb/ServicesBanner'
import BannerImg from '../../assets/images/bannners/Branding.png'
import ImageBorderStyleRight from '../../components/haseeb/ImageBorderStyleRight'
import ImageBorderStyleLeft from '../../components/haseeb/ImageBorderStyleLeft'
import Img1 from '../../assets/images/branding/Img1.jpg'
import Img2 from '../../assets/images/branding/Img2.jpg'
import Img3 from '../../assets/images/branding/Img3.jpg'
import Img4 from '../../assets/images/branding/Img4.jpg'
import { Link } from 'react-router-dom'
import MiddleBannerPrimary from '../../components/haseeb/MiddleBannerPrimary'
import MiddleBannerSecondary from '../../components/haseeb/MiddleBannerSecondary'
import { Col, Row } from 'reactstrap'
import { FaDotCircle } from 'react-icons/fa'

const Branding = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <ServicesBanner
    BannerImg={BannerImg}
    SubTitle="Take The Game-Changing Lead"
    Title="Branding"
    />
<section id='website-responsive' className="container-fluid px-0">
<div id='website-responsive'>
        <div className='right-padding8rem1 ps-lg-5 ps-4 py-lg-5 py-4'>
            <Row className=''>
                <Col lg='3' className=''>
                    <div className='pb-md-3 pb-lg-0 pb-3'>
                        <div className='d-flex align-items-center'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/healthcare-solutions' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Healthcare Solutions
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/branding/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-24 text-mulish fw-500 text-ss-primary'>
                                    Branding
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/graphic-designing' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Graphic Designing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/content-writing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Content Writing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/web-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Web Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/app-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    App Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/search-engine-optimization/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Search Engine Optimization
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/social-media-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Social Media Marketing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/video-animation/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Video Animation
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/ppc-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                PPC Marketing
                            </span>
                                </Link>
                        </div>
                    </div>
                </Col>

                <Col className='ps-lg-4'>
                    <div className=''>
                        <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Comprehensive Agency Brand Management Solutions
                            </h2>
                        </div>

                        <div className='me-lg-5'>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            Branding is like a guiding light for businesses, helping them stand out in a crowded market. At <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication, </Link>  we're experts at boosting this journey by creating partnerships that benefit businesses and their audiences. We know the ins and outs of the market and use creative ideas to tell compelling stories about brands. This helps us attract and keep loyal customers by making brands authentic and relatable. By seamlessly using various methods and doing thorough research, we help businesses succeed by bringing brands and consumers together. Our primary focus is on supporting startups and innovative thinkers. Our skilled team specializes in making <Link className='text-decoration-none text-ss-primary' to="/services/web-development"> unique websites,</Link> crafting brand identities, and running effective <Link className='text-decoration-none text-ss-primary' to="/services/social-media-marketing"> digital marketing campaigns </Link>  that transform your business story. With our creative branding services, you can reach new heights and break through limits, embracing innovation and achieving great success.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>


    <section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-center gy-4'>
        <div className="col-11 col-md-4">
          <img className='w-100' src={Img4} alt="" />
        </div>
        <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Creating Win-Win Collaborations to Amplify Brand Impact
             </div>
         </div>
         <div className='me-lg-5'>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Step into the world of ZM Communication, a vibrant <Link className='text-decoration-none text-ss-primary' to="/services/ppc-marketing"> marketing agency </Link> in Canada. Our strength is crafting strategies that stand out and building solid collaborations that supercharge brand impact. With a firm commitment to excellence, we expertly navigate the relationship between your customer base, your brand's core, and your business results. Picture a journey fueled by unwavering dedication, where our quest for perfection meets the art of creating mutually beneficial partnerships that reshape how brands thrive.
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerPrimary
    Title="The Branding Go-getters The Visual Identity"
    Para="We buy what we see. First impressions are everything, and the visual identity that forms an overall brand always leaves a strong, unforgettable impression. To develop unique and scroll-stopping content, we work with our clients to express their visual aspects reflected in tailor-made logo designs, website design, and custom iconography."
    />

<section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-start'>
      <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             The Strategy: Igniting Grounds for Success
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Our journey begins with establishing solid foundations, a defining brand voice that resonates with the audience and swiftly garners a dedicated following. This newfound audience transforms into loyal consumers, forging an unbreakable bond between them and the brand.
             </p>
        </div>
        </div>
        <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             The Voice: Resonating Impressions
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             A brand's voice is amplified through compelling slogans and memorable jingles, while its verbal language plays a pivotal role. As a premier digital marketing agency in Toronto, we skillfully synchronize the brand's tone with the audience, ensuring triumphant campaign launches and fostering genuine connections. We leave indelible impressions etched in hearts and minds.
             </p>
        </div>
        </div>
      </div>
    </section>
    <MiddleBannerSecondary
    Title="The Regulations: Seamless Integration"
    Para="What's more? Our Canadian digital marketing agency orchestrates seamless campaigns across diverse mediums and platforms, meticulously guided by impactful art direction. This includes a harmonious blend of digital, print, and television executions. Photography and graphic imagery, versatile vehicles of creative expression, are potent tools for setting the desired brand tone."
    />
    <div className='pt-4 pt-lg-0'/>
    <ImageBorderStyleLeft
    Img={Img1}
    Title="Mark Your Territory"
    Para="To stand tall, one must understand the playing field. Our methodology involves studying our direct rivals and drawing insights from diverse industries globally. By comprehending how competitors engage with consumers and portray themselves, we pinpoint the unique territory a brand can occupy, setting the stage for unparalleled branding services."
    />
    
    <ImageBorderStyleRight
    Img={Img2}
    Title="Know Your Consumers"
    Para="Success hinges on meticulous data collection, uncovering the who, where, and how of your audience's preferences. Armed with this knowledge, we tailor a range of techniques that align seamlessly, culminating in enhanced branding services that resonate deeply with your consumers."
    />

    <ImageBorderStyleLeft
    Img={Img3}
    Title="Stand Out or Stand Down"
    Para={<>
    Whether embarking on a rebranding journey or starting anew, ZM Communication, your innovative digital marketing agency in Canada, introduces fresh dynamics to your business landscape. Curious to explore our branding projects and <Link className='text-ss-primary text-decoration-none' to="/packages"> packages </Link> ? Contact us; our fearless experts will guide you through the transformative process.
    </>}
    />
    </section>
    </>
  )
}

export default Branding
import React from 'react'
import { FaDotCircle, FaListOl } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const ImprovingPatientExperience = () => {
  return (
    <div id='website-responsive'>
        <div className='right-padding8rem1 ps-lg-5 ps-4 py-lg-5 py-4'>
            <Row className=''>
            <Col lg='3' className=''>
                    <div className='pb-md-3 pb-lg-0 pb-3'>
                        <div className='d-flex align-items-center'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/healthcare-solutions' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                                <span className='fs-ss-24 text-mulish fw-500 text-ss-primary'>
                                    Healthcare Solutions
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/branding/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Branding
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/graphic-designing' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Graphic Designing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/content-writing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Content Writing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/web-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                                <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Web Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/app-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    App Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/search-engine-optimization/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Search Engine Optimization
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/social-media-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Social Media Marketing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/video-animation/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Video Animation
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/ppc-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                PPC Marketing
                            </span>
                                </Link>
                        </div>
                    </div>
                </Col>

                <Col className='ps-lg-4'>
                    <div className=''>
                        <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                                Improving patients' experience through Digital Healthcare Solutions
                            </h2>
                        </div>

                        <div className='me-lg-5'>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            Before consulting any hospital or doctor, it has become a frequent practice to research, browse, review and check ratings. Undoubtedly, managing a professional healthcare center or hospital takes time from your regular activities as a physician or practitioner. To make things run smoothly, medical providers worldwide prefer using an efficient digital healthcare approach to promote their expertise. Professional help from a digital marketing agency is required to assist your team and patients, recruit new patients, and develop stronger bonds with the existing ones. <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication </Link> brings cutting-edge digitalization to the healthcare industry to help you make a prominent name in the healthcare world.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default ImprovingPatientExperience
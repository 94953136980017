import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Banner from '../components/haseeb/Banner'
import BannerImg from '../assets/images/bannners/MainServicesPage.png'

const ServicePage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
        return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <Banner
    BannerImg={BannerImg}
    />
    </>
  )
}

export default ServicePage
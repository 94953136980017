import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button } from 'reactstrap'
import Websites from './Websites'
import SocialMediaPosts from './SocialMediaPosts'
import Videos from './Videos'

const PortfolioHeader = () => {

const handleClick = (btn_id) => {
    setID(btn_id)
    console.log('btn_id',btn_id)
}

    const [id,setID] = useState(1)

  return (
    <>
    <div id='website-responsive container-fluid'>
    <Tabs>
    <TabList className="text-center fs-ss-16 fw-600 pt-4">
      <Tab> WEBSITES </Tab>
      <Tab> SOCIAL MEDIA POSTS </Tab>
      <Tab> VIDEOS </Tab>
    </TabList>

    <TabPanel>
        <Websites/>
    </TabPanel>
    <TabPanel>
        <SocialMediaPosts/>
    </TabPanel>
    <TabPanel>
        <Videos/>
    </TabPanel>
  </Tabs>  
    </div>
    </>
  )
}

export default PortfolioHeader
import React, { useState, useEffect } from 'react'
import Header2 from '../../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ServicesBanner from '../../components/haseeb/ServicesBanner'
import BannerImg from '../../assets/images/bannners/GraphicDesigning.png'
import ImageBorderStyleLeft from '../../components/haseeb/ImageBorderStyleLeft'
import Img1 from '../../assets/images/graphic-designing/Img1.jpg'
import Img2 from '../../assets/images/graphic-designing/Img2.jpg'
import StrengthenYourPresence from '../../assets/images/graphic-designing/Strengthen-your-presence.jpg'
import MiddleBannerPrimary from '../../components/haseeb/MiddleBannerPrimary'
import MiddleBannerSecondary from '../../components/haseeb/MiddleBannerSecondary'
import { Link } from 'react-router-dom'
import { ChevronRight } from 'react-bootstrap-icons'
import { Col, Row } from 'reactstrap'
import { FaDotCircle } from 'react-icons/fa'
import ReadMoreOrLess from '../../components/haseeb/ReadMoreOrLess'

const GraphicDesigning = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    const [isReadMoreshown, setReadMoreShown] = useState(false)
    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState)
    }
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <ServicesBanner
    BannerImg={BannerImg}
    SubTitle="A Fusion Of Art And Innovation"
    Title="Graphic Designing"
    />
<section id='website-responsive' className="container-fluid px-0">
<div id='website-responsive'>
        <div className='container-fluid ps-lg-5 ps-4 py-lg-5 py-4'>
            <Row className=''>
                <Col lg='3' className=''>
                    <div className='pb-md-3 pb-lg-0 pb-3'>
                        <div className='d-flex align-items-center'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/healthcare-solutions' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Healthcare Solutions
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/branding/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Branding
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/graphic-designing' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-24 text-mulish fw-500 text-ss-primary'>
                                    Graphic Designing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/content-writing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Content Writing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/web-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Web Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/app-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    App Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/search-engine-optimization/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Search Engine Optimization
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/social-media-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Social Media Marketing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/video-animation/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Video Animation
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/ppc-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                PPC Marketing
                            </span>
                                </Link>
                        </div>
                    </div>
                </Col>

                <Col className='ps-lg-4 d-none d-lg-block'>
                        <div className='d-block d-lg-flex align-items-start'>
                          <div className='w-75'>
                          <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Logo Design that Reflects Your Brand's Personality and Values
                            </h2>
                        </div>
                        <ReadMoreOrLess
                        Para="Your brand's logo isn't just a picture – it's a powerful way to show what your brand stands for. But making a logo that says all these things can be challenging. That's where ZM Communication comes in. We create logos that are about more than looks. We know a logo should mean something to the people who see it, showing what your brand is about and making people trust you. Our team is good at this. We look deep into your brand and make logos that tell stories, make you feel things, and stick in your mind. We mix creativity with innovative thinking to create a logo that shows what your brand is and what it believes in. ZM Communication is good at making logos that are special and unique. We don’t just create logos that look nice, but we make logos with significant meaning. When people see your logo, they should understand your brand and feel like they can trust it. Our graphic designers in Toronto are skilled at doing this. They dig deep into your brand to understand it, creating logos that tell stories and make you feel something. These logos are made with creativity and innovative ideas, showing off your brand and its reputation. At ZM Communication, we also do other cool things with design, like making pictures for your brand that look awesome. We ensure that everything we create is unique, just like your brand."
                        limit={430}
                        />
                            </div>
                            <div className='px-lg-5'>
                            <img className='' src={StrengthenYourPresence} alt="Strengthen Your Presence" style={{width:"400px"}} />
                        </div>
                    </div>
                </Col>

                <Col className='d-block d-lg-none'>
                          <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Logo Design that Reflects Your Brand's Personality and Values
                            </h2>
                        </div>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            Your brand's logo isn't just a picture – it's a powerful way to show what your brand stands for. But making a logo that says all these things can be challenging. That's where <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication </Link> comes in. We create logos that are about more than looks. We know a logo should mean something to the people who see it, showing what your brand is about and making people trust you. Our team is good at this. We look deep into your brand and make logos that tell stories, make you feel things, and stick in your mind. We mix creativity with innovative thinking to create a logo that shows what your brand is and what it believes in.  

                            ZM Communication is good at making logos that are special and unique. We don’t just create logos that look nice, but we make logos with significant meaning. When people see your logo, they should understand your brand and feel like they can trust it. Our graphic designers in Toronto are skilled at doing this. They dig deep into your brand to understand it, creating logos that tell stories and make you feel something. These logos are made with creativity and innovative ideas, showing off your brand and its reputation. At ZM Communication, we also do other cool things with design, like making pictures for your brand that look awesome. We ensure that everything we create is unique, just like your brand. 
                            </p>
                            <div className='px-lg-5'>
                            <img className='w-100' src={StrengthenYourPresence} alt="Strengthen Your Presence"/>
                    </div>
                </Col>
            </Row>
        </div>
    </div>

    <section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-center gy-4'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Meet Our Skilled Graphic Designers in Toronto
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             Are you planning to rebrand or strengthen your brand identity? Do you want things to be more creative and impactful? Contact our team to revamp your websites, brochures, <Link className='text-decoration-none text-ss-primary' to="/services/social-media-marketing/"> digital marketing, </Link> and advertising content with the best design options. If you're looking for excellent graphic designers in Toronto who can make your ideas come to life, our team is here. We're a bunch of skilled Toronto graphic designers ready to create designs that catch people's attention, talk to them, and make them feel something.
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerPrimary
    Title="Sensory Indulgent"
    Para="Create Your Niche! Often, design, aesthetics, and branding do not sync well. ZM Communication provides graphic design services in Canada, with a strong success ratio in making stunning visual designs for their clients. Moreover, improving marketing conversion ratios through outcome-oriented and creating an impactful presence of a product or service that stands out as a strong brand identity!"
    />
    
    <section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-center gy-4'>
        <div className="col-11 col-md-5">
          <img className='w-100' src={Img2} alt="" />
        </div>
        <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Graphic Designer Toronto: Making Cool Stuff
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             We’re proud of our team of graphic designers based in Toronto. They’re super creative and pay attention to minute details. They know how to take your ideas and turn them into unique designs. Each project is particular, and they always think about how to show their brand’s personality in the techniques they make. <br/><br/>
             We’re dedicated to creating remarkable changes as a company that makes brands better worldwide. We work super hard to <Link className='text-decoration-none text-ss-primary' to="/services/search-engine-optimization/"> get the best results possible. </Link> We use brilliant thinking and unique designs, plans, and understanding of brands to help businesses grow and become even better.              
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerSecondary
    Title="Relevant Digitization"
    Para={<>When custom-ordered professional print design becomes a part of your brand's marketing strategy, it brings incredible ideas to life while reflecting your brand's image and delivering the right message to your customers. In addition, print designs can be innovative and creative, providing a brief example of the products to be marketed to customers through brochures, direct mail, books, packaging materials, and more. Consulting a digital marketing agency in Canada can provide a creative concept and inspiration for impeccable brand marketing and successful business growth. 
    <div className='pt-4'>
    <Link to="/packages" className="text-decoration-none text-white fs-ss-18 fw-500 border-2 border-white border py-2 px-3"> View Packages <ChevronRight className='fs-ss-22'/> </Link>
    </div>
    </>}
    />
        <div className='pt-4 pt-lg-0'/>
        <ImageBorderStyleLeft
    Img={Img1}
    Title="Designing for Digital World"
    Para="At ZM Communication, we know that making an infographic post isn't just about pictures – it's about showing what your brand is all about. Our team is excellent at understanding what makes your brand, business, or company memorable and turning that into a single image. We work with you to create appealing posts that people will like and show your brand and what it cares about. Our Team of designers uses their creativity and latest ideas to turn your posts into something about your brand that people will remember."
    />
    
    <section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Main Takeaway
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             ZM Communication, among the best graphic design companies, does everything to make your brand look great. Our graphic designers are fantastic at making logos that show what your brand is like and look good. They’re also experts in creating graph and web designs, brochures, business cards, <Link className='text-decoration-none text-ss-primary' to="/services/video-animation/"> video animations, </Link> posters, and banners. These things use words, colors, and layouts to grab people’s attention. <br/><br/>
             
             We also know all about the online world. Our designers make things look good on the internet, like pictures for websites, social media, and ads. They know how important it is for your brand to look the same everywhere, so they work closely with you to ensure it always looks and feels suitable to your audience.              </p>
        </div>
        </div>
      </div>
    </section>
    </section>
    </>
  )
}

export default GraphicDesigning
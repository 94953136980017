import React from 'react'

const FAQs = () => {
    return (
        <div id='website-responsive'>
            <section className='faq left-padding8rem1 right-padding10rem1 py-lg-5 py-md-4 py-4' style={{ borderRadius:'30px'}}>

                <div className='container-zk'>

                    <div className='row pt-md-0 pt-0 pb-4' id="faq-home">

                        <div className='col-md10 mxauto p-md5 pb-4' style={{ borderRadius: "30px" }}>

                            <div className='accordion accordion-flush px-md-4 px-2' id='accordionFlushExample'>

                                <div className='accordion-item'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingOne'>

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseOne' aria-expanded='false' aria-controls='flush-collapseOne'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 fw-700 text-zksecondary' style={{ fontWeight: "600" }}>What pages should my website have?</span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseOne' className='accordion-collapse collapse' aria-labelledby='flush-headingOne' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>Every website should include the following elements: a homepage, an about page, a contact page, services, FAQs, and testimonials. </span>

                                        </div>

                                    </div>

                                </div>

                                {/* <hr /> */}



                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingThree'>

                                        <button style={{ backgroundColor: '#f1f1f1', }} className='accordion-button collapsed text-ss-focus' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseThree' aria-expanded='false' aria-controls='flush-collapseThree'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1   text-zk-secondary' style={{ fontWeight: "600" }}>Should I update the content on my website? </span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseThree' className='accordion-collapse collapse' aria-labelledby='flush-headingThree' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body ' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>Yes, up-to-date information is an essential part. A successful website will engage customers and provide new information and content.
                                            </span>

                                        </div>

                                    </div>

                                </div>



                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingFour'>

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseFour' aria-expanded='false' aria-controls='flush-collapseFour'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 text-zk-secondary' style={{ fontWeight: "600" }}>What exactly is UX/UI?</span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseFour' className='accordion-collapse collapse' aria-labelledby='flush-headingFour' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>UI means a user interface that includes whatever the user sees and interacts with on a digital platform. UX is an abbreviation for user experience, it describes how a person feels when engaging with an interface.

                                            </span>

                                        </div>

                                    </div>

                                </div>

                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingFive'>

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseFive' aria-expanded='false' aria-controls='flush-collapseFive'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 text-zk-secondary' style={{ fontWeight: "600" }}>Why is SEO important? </span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseFive' className='accordion-collapse collapse' aria-labelledby='flush-headingFive' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>Search Engine Optimization employs on-page and off-page modifications and methods to improve your page’s ranking on search engines.

                                            </span>

                                        </div>

                                    </div>

                                </div>

                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingSix'>

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseFSix' aria-expanded='false' aria-controls='flush-collapseFSix'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 text-zk-secondary' style={{ fontWeight: "600" }}>What are the keywords? </span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseFSix' className='accordion-collapse collapse' aria-labelledby='flush-headingSix' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>People use the words and phrases while searching, generally on a search engine. We utilize these phrases when searching the web for pages, photographs, videos, blogs, or any other type of information or material.

                                            </span>

                                        </div>

                                    </div>

                                </div>



                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingSeven'>

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseFSeven' aria-expanded='false' aria-controls='flush-collapseFSeven'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 text-zk-secondary' style={{ fontWeight: "600" }}>Is it necessary to have a blog on my website?  </span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseFSeven' className='accordion-collapse collapse' aria-labelledby='flush-headingSeven' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>Yes, the most popular venue for distributing marketing material is a blog. Consumers are drawn to blogs because they provide amusement and valuable information.

                                            </span>

                                        </div>

                                    </div>

                                </div>

                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingEight'>

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseEight' aria-expanded='false' aria-controls='flush-collapseEight'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 text-zk-secondary' style={{ fontWeight: "600" }}>Which social media platforms are important for any company to gain more traffic?</span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseEight' className='accordion-collapse collapse' aria-labelledby='flush-headingEight' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>Facebook, Instagram, Twitter, LinkedIn, and YouTube are excellent social media channels many users are inclined to use.

                                            </span>

                                        </div>

                                    </div>

                                </div>

                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingNone' >

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseNine' aria-expanded='false' aria-controls='flush-collapseNine'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 text-zk-secondary' style={{ fontWeight: "600" }}>What exactly is content marketing? </span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseNine' className='accordion-collapse collapse' aria-labelledby='flush-headingNone' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>Content marketing entails producing and distributing internet marketing resources to increase brand awareness and sales.

                                            </span>

                                        </div>

                                    </div>

                                </div>

                                <div className='accordion-item pt-'>

                                    <h2 className='accordion-header' style={{ borderBottom: '' }} id='flush-headingTen'>

                                        <button style={{ backgroundColor: '#f1f1f1' }} className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#flush-collapseTen' aria-expanded='false' aria-controls='flush-collapseTen'>

                                            <span className='fs-ss-18 text-philos my-2 ms-1 text-zk-secondary' style={{ fontWeight: "600" }}>Is email marketing a practical approach for a brand?</span>

                                        </button>

                                    </h2>

                                    <div id='flush-collapseTen' className='accordion-collapse collapse' aria-labelledby='flush-headingTen' data-bs-parent='#accordionFlushExample'>

                                        <div className='accordion-body' style={{ backgroundColor: '#f1f1f1', borderBottom: '1px solid #ECD3B4' }}>

                                            <span className='fs-ss-15 d-flex text-mulish fw-500 ms-md-3'>Yes, it is effective and more active than any other accounts on any social media, globally. </span>

                                        </div>

                                    </div>

                                </div>



                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </div>
    )
}

export default FAQs
// import './App.css';
import Path from './routes/Path';
import './assets/css/style.css'
import 'react-tabs/style/react-tabs.css';

function App() {
  return (
    <div className=''>
        <Path/> 
    </div>
  );
}

export default App;

import React from 'react'

const PortfolioBanner = () => {
  return (
    <div id='website-responsive'>
        <div className=''>
            <img src='portfolio/ZM-Portfolio-Banner-v1.1.jpg' alt='' className='' width='100%' />
        </div>
    </div>
  )
}

export default PortfolioBanner
import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import TermsConditions from '../components/terms-conditions/TermsConditions'

const TermsConditionspage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
  return (
    <>
    <div  style={{ overflow: 'hidden', backgroundImage:'url("/bg-img/MicrosoftTeams-image.png")', width:'100%', backgroundAttachment:'fixed' }}>

<HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>Terms and Conditions - Z.M.Communication Inc.</title>
          <link rel="canonical" href="https://zmcommunication.org/terms-and-conditions/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Terms and Conditions - Z.M.Communication Inc." />
          <meta property="og:description" content="Terms &amp; Conditions Graphic&nbsp;Design Services Z.M. Communication retains the right to use work produced for the client as part of its portfolio materials in its online and offline portfolios. Any additional work, revisions, or variations outside of the original Visual Brief will be charged at CAD 120 per hour, billed in 15-minute increments. All prices &hellip; Terms and Conditions Read More &raquo;" />
          <meta property="og:url" content="https://zmcommunication.org/terms-and-conditions/" />
          <meta property="og:site_name" content="Z.M.Communication Inc." />
          <meta property="article:publisher" content="https://www.facebook.com/zmcomm" />
          <meta property="article:modified_time" content="2022-06-02T09:37:04+00:00" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@ZMCOMM" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="5 minutes" />
        </Helmet>
      </HelmetProvider>
    <Header2/>

        <TermsConditions/>
    </div>
    </>
  )
}

export default TermsConditionspage
import React from 'react'
import { EnvelopeFill, Facebook, GeoAltFill, Instagram, Linkedin, Mailbox, Pinterest, Twitter, Youtube } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import FooterLogo from '../assets/images/FooterLogo.png'

const Footer = () => {
    return (
        <>
        <div id='website-responsive' style={{ overflow: '' }}>
            <div className='left-padding8rem1 right-padding8rem1 py-5' style={{ backgroundColor: '#2F2E2A' }}>
                <Row className=''>
                    <Col md='12' lg='4' className=''>
                        <div className='d-md-flex justify-content-md-center justify-content-lg-start'>
                            <img src={FooterLogo} alt='' width='250px' className='' />
                        </div>
                        <div className='pt-lg-5 pe-md-5 pe-lg-0 py-4 py-lg-0'>
                            <p className='fs-ss-16 text-mulish fw-500 text-white'>
                                Z.M. Communication is a full-service digital marketing agency founded to enable businesses to succeed in a wider range of online projects.
                            </p>
                        </div>
                    </Col>

                    <Col md='12' lg='4' className='d-md-flex justify-content-md-start justify-content-lg-center pb-4 pb-lg-0'>
                        <div className=''>
                            <div className='text-ss-primary'>
                                <h3 className='fs-ss-32 text-philos fw-700 ' style={{ color: '#FE9B1F' }}>
                                    Services
                                </h3>
                            </div>
                            <div className=''>
                                <div className=''>
                                    <Link to='/services/healthcare-solutions' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Healthcare Solutions
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/branding' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Branding
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/graphic-designing' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Graphic Designing
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/content-writing' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Content Writing
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/web-development' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Website Development
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/search-engine-optimization' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Search Engine Optimization
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/social-media-marketing' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Social Media Marketing
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/video-animation' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Video Animation
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/services/ppc-marketing' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            PPC Marketing
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md='12' lg='4' className='d-md-flex justify-content-md-start justify-content-lg-center ps-lg-4'>
                        <div className=''>
                            <div className='text-ss-primary'>
                                <h3 className='fs-ss-32 text-philos fw-700 ' style={{ color: '#FE9B1F' }}>
                                    Quick Links
                                </h3>
                            </div>
                            <div className=''>
                                <div className=''>
                                    <Link to='testimonials' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Testimonials
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/digital-marketing-agency-in-mississauga' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Digital Marketing in Mississauga
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/referral-program' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Referral Program
                                        </p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link to='/terms-and-conditions' className='' style={{ textDecoration: 'none' }}>
                                        <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                            Terms & Conditions
                                        </p>
                                    </Link>
                                </div>

                                <div className='text-ss-primary mt-md-4 mt-4 mb-md-3 mb-3'>
                                    <h3 className='fs-ss-32 text-philos fw-700 ' style={{ color: '#FE9B1F' }}>
                                        Get In Touch
                                    </h3>
                                </div>
                                <div className=''>
                                    <div className='d-flex mb-1'>
                                        <a href='mailto: care@zmcommunication.org' className='' style={{ textDecoration: 'none' }}>
                                        <div className='d-flex align-items-center'>
                                            <EnvelopeFill className='me-2 fs-ss-16' style={{ color:'#FE9B1F'}}/>
                                            <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                                care@zmcommunication.org
                                            </p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='d-flex'>
                                        <a href='https://www.google.com/maps/place/57+Ketchum+Crescent,+Markham,+ON+L3S+3G3,+Canada/@43.8421658,-79.2715952,17z/data=!3m1!4b1!4m5!3m4!1s0x89d4d6949e00f1bd:0x66017c97e75aab4f!8m2!3d43.842162!4d-79.2694065' className='' style={{ textDecoration: 'none' }}>
                                            <div className='d-flex align-items-center'>
                                            <GeoAltFill className='me-2 fs-ss-16' style={{ color:'#FE9B1F'}} />
                                            <p className='fs-ss-16 text-mulish mb-1 fw-500 text-white'>
                                                Markham ON Canada
                                            </p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <section className="container-fluid py-2 text-white fs-ss-15 text-center" style={{backgroundColor:"#030a0e"}}>
            <div className="row justify-content-evenly">
                <div className="col-11 col-lg-5 text-lato">
                Copyright © 2023 Z.M.Communication Inc. | All Rights Reserved.
                </div>
                <div className="col-11 col-lg-5 text-center">
                <div className='d-flex justify-content-center align-items-center'>
                <div className='me-3 fs-ss-14'>
                                        <a className='text-white text-decoration-none' href="https://www.facebook.com/zmcomm" target='_blank'>
                                        <Facebook/>
                                        </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-white text-decoration-none' href="https://www.instagram.com/zmcommunication_org/" target='_blank'>
                                        <Instagram/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-white text-decoration-none' href="https://twitter.com/ZMCOMM" target='_blank'>
                                        <Twitter/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-white text-decoration-none' href="https://www.linkedin.com/company/zm-communication/about/" target='_blank'>
                                        <Linkedin/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-white text-decoration-none' href="https://www.pinterest.co.uk/Zmcommg/" target='_blank'>
                                        <Pinterest/>
                                    </a>
                                    </div>
                                    <div className='me-3 fs-ss-14'>
                                    <a className='text-white text-decoration-none' href="https://www.youtube.com/channel/UCF92-mO-441yQpSfFLaDihw" target='_blank'>
                                        <Youtube/>
                                    </a>
                                    </div>
                                </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Footer
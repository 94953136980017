import React, { useEffect } from 'react'
import Header2 from '../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Img1 from '../assets/images/referral/Img1.webp';
import Img2 from '../assets/images/referral/Img2.webp';
import Img3 from '../assets/images/referral/Img3.webp';
import { Button, Form, Input } from 'reactstrap';

const ReferralProgram = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
        return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
        <section id='website-responsive' className="container-fluid bg-referral text-lato" style={{paddingTop:"200px"}}>
            <div className="row justify-content-center align-items-end pt-md-5 mt-md-5 px-lg-5 mx-lg-5">
            <div className="col-12 col-lg-4 pt-lg-5 mt-lg-5">
                    <div className='bg-ss-primary py-4 py-lg-5 text-center'>
                        <a className="text-black fs-ss-28 text-decoration-none fw-600 border-white border-2 border py-2 px-4" href="#referral_program">
                            Come & Join
                        </a>
                    </div>
                </div>

                <div className="col-8">
                </div>
            </div>
        </section>

        <section id='website-responsive'  className="container-fluid pb-4 text-lato" style={{backgroundColor:"#4d4d4d"}}>
            <div className="row justify-content-center align-items-start px-lg-5 mx-lg-5 gy-4">
            <div className="col-12 col-lg-4">
                    <div className='fs-ss-52 text-black fw-700 py-5 text-center lh-sm' style={{backgroundColor:"#fca942"}}>
                    REFERRAL <br/> PROGRAM
                    <div className='fs-ss-22 fw-500  text-start pt-4 px-5'>
                    <div className='text-white bg-ss-primary p-2'>
                    Refer a Friend or Business and Earn *$100 to $1000 and even more!
                    </div>
                    </div>
                    </div>
                </div>

                <div className="col-11 col-lg-8 text-ss-primary align-self-center fs-ss-35">
                    <div className="px-5 mx-lg-5 lh-sm fw-600" style={{borderLeft:"2px solid white"}}>
                Would you like to make some extra cash by referring us to someone you know?
                </div>
                </div>
            </div>
        </section>

        <div id='website-responsive'  className='container-fluid col-11 col-lg-9 text-center fs-ss-18 fw-500 pt-4 pt-lg-5 text-mulish'>
        Take advantage of our marketing referral program if you know anyone who could benefit from our website design, search engine optimization, social media marketing, reputation management, content, and email marketing or graphic design services. <br className='d-none d-lg-block'/> There is no limit to how many clients you refer us; for every referral, you will receive a hefty reward!
        </div>

        <section id='website-responsive'  className="container-fluid py-4 py-lg-5">
            <div className='col-10 ps-5 ms-5 fs-ss-35 fw-600 text-lato'>
            How It Works
            </div>
            <div className="row justify-content-evenly align-items-center pt-4 gy-4">
                <div className="col-11 col-lg-5 text-center d-none d-lg-block">
                    <img className='w-75' src={Img1} alt="" />
                </div>
                <div className="col-11 col-lg-4">
                    <div className='fs-ss-32 pb-3 text-ss-primary fw-600 text-lato'>
                    Refer
                    </div>
                    <div className='fs-ss-18 fw-500 text-lato'>
                    If someone you know is interested in digital marketing services, ask them to fill out our contact form. They just need to provide their contact information, let us know which services they are interested in, and place your name in the referral box.
                    </div>
                </div>
                <div className="col-11 col-lg-5 text-center d-block d-lg-none">
                    <img className='w-75' src={Img1} alt="" />
                </div>
            </div>

            <div className="row justify-content-evenly align-items-center pt-4 gy-4">
                <div className="col-11 col-lg-4">
                    <div className='fs-ss-32 pb-3 text-ss-primary fw-600 text-lato'>
                    Meet
                    </div>
                    <div className='fs-ss-18 fw-500 text-lato pb-3'>
                    We will then work with your referral to provide them with a customized marketing solution that best fits their business.
                    </div>
                    <div className='fs-ss-14 fw-400'>
                    *The commission is paid upon a successful referral that moves forward with our services and makes an initial payment.
                    </div>
                </div>
                <div className="col-11 col-lg-5 text-center">
                    <img className='w-75' src={Img2} alt="" />
                </div>
            </div>

            <div className="row justify-content-evenly align-items-center pt-4 gy-4">
                <div className="col-11 col-lg-5 text-center d-none d-lg-block">
                    <img className='w-75' src={Img3} alt="" />
                </div>
                <div className="col-11 col-lg-4">
                    <div className='fs-ss-32 pb-3 text-ss-primary fw-600 text-lato'>
                    Earn
                    </div>
                    <div className='fs-ss-18 fw-500 text-lato pb-3'>
                    We will send you a commission through PayPal or bank transfer for the referral when they start working with us. Remember, you can refer as many people as you would like; the referral program has no limitations or caps.
                    </div>
                    <div className='fs-ss-14 fw-400'>
                    *Minimum worth of a project to be $500. The referral amount may go up depending upon the project size.
                    </div>
                </div>
                <div className="col-11 col-lg-5 text-center d-block d-lg-none">
                    <img className='w-75' src={Img3} alt="" />
                </div>
            </div>
        </section>
        
        <section id='website-responsive'  className="container-fluid py-4 py-lg-5 bg-abovefooter text-lato">
        <div className="row justify-content-evenly align-items-center pt-4">
                <div className="col-11 col-lg-5 col-xl-4">
                    <div className='pb-4'>
                        <span className='d-block d-lg-inline bg-ss-primary text-black fw-bold fs-ss-18 px-4 py-2 rounded-4'>
                        Launch your business with an impact!
                        </span>
                    </div>
                    <div className='pb-4 fs-ss-35 fw-600'>
                    Want in? <br/>
                    Start Referring
                    </div>
                    <div className='pb-4 fs-ss-18'>
                    If you have any questions, feel free to contact us directly at <a className='text-ss-primary text-decoration-none' href='tel:+14167213222' target='_blank'> +14167213222 </a> or email us at <a className='text-ss-primary  text-decoration-none' href='mailto:care@zmcommunication.org' target='_blank'> care@zmcommunication.org </a>
                    </div>
                </div>
                <div className="col-11 col-lg-5">
                <div id='referral_program' className=''>
                            <Form>
                                <Input className='mb-lg-4 mb-3' type='text' name='name' placeholder='First Name and Last Name' required style={{ borderRadius: 0 }} />
                                <Input className='mb-lg-4 mb-3' type='email' name='email' placeholder='Email' required style={{ borderRadius: 0 }} />                                <Input className='mb-lg-4 mb-3' type='number' name='number' placeholder='Number' required style={{ borderRadius: 0 }} />

                                <Input className='mb-lg-4 mb-3' type='textarea' name='details' placeholder='Details' style={{ borderRadius: 0 }} />

                                <Button type='submit' style={{ width: '100%', borderRadius: 0 }}>SEND</Button>
                            </Form>
                        </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default ReferralProgram
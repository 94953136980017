import React, { useEffect } from 'react'
import Header2 from '../../layout/Header2'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ServicesBanner from '../../components/haseeb/ServicesBanner'
import BannerImg from '../../assets/images/bannners/SocialMediaMarketing.png'
import Img1 from '../../assets/images/smm/Img1.jpg'
import MiddleBannerPrimary from '../../components/haseeb/MiddleBannerPrimary'
import MiddleBannerSecondary from '../../components/haseeb/MiddleBannerSecondary'
import { ChevronRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { FaDotCircle } from 'react-icons/fa'

const SocialMediaMarketing = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[])
    
    return (
    <>
    <HelmetProvider>
    <Helmet prioritizeSeoTags>
        <title>Digital Marketing Agency in Canada - Z.M.Communication Inc.</title>
    </Helmet>
    </HelmetProvider>
    <Header2/>
    <ServicesBanner
    BannerImg={BannerImg}
    SubTitle="Let’s Make You Viral"
    Title="Social Media Marketing"
    />
<section id='website-responsive' className="container-fluid px-0">
<div id='website-responsive'>
        <div className='right-padding8rem1 ps-lg-5 ps-4 py-lg-5 py-4'>
            <Row className=''>
                <Col lg='3' className=''>
                    <div className='pb-md-3 pb-lg-0 pb-3'>
                        <div className='d-flex align-items-center'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/healthcare-solutions' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Healthcare Solutions
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/branding/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Branding
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/graphic-designing' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Graphic Designing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/content-writing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Content Writing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/web-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Web Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/app-development/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    App Development
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/search-engine-optimization/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                    Search Engine Optimization
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/social-media-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-24 text-mulish fw-500 text-ss-primary'>
                                Social Media Marketing
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/video-animation/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                Video Animation
                            </span>
                                </Link>
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <FaDotCircle className='text-ss-primary me-3'/>
                                <Link to='/services/ppc-marketing/' className='' style={{ textDecoration:'none', cursor:'pointer'}}>
                            <span className='fs-ss-18 text-mulish fw-500 text-ss-black'>
                                PPC Marketing
                            </span>
                                </Link>
                        </div>
                    </div>
                </Col>

                <Col className='ps-lg-4'>
                    <div className=''>
                        <div className='mb-lg-4 mb-3'>
                            <h2 className='fs-ss-36 text-philos fw-800 text-ss-black'>
                            Maximize ROI With Affordable Social Media Marketing Services
                            </h2>
                        </div>

                        <div className='me-lg-5'>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
                            In today's digital world, many businesses use social media to tell people about their products and services. At ZM Communication, we can help companies use social media in an affordable way and get them more customers. Moreover, we offer affordable social media marketing services that amplify your online presence and maximize you <Link className='text-decoration-none text-ss-primary' to="/services/ppc-marketing/"> return on investment. </Link> Our team knows a lot about how social media works, and we can make unique plans that are just right for each business. We can help with many things on social media, like making excellent posts and deciding when to put them online. We also talk to people who like the posts and find out how everything is going. If a business wants more people to know about it, visit its website, or buy things, we can make that happen without costing too much. Social media is a super tool for businesses to talk to people and grow. At <Link className='text-decoration-none text-ss-primary' to="/"> ZM Communication, </Link> we know how to use it in an intelligent way that doesn't cost a lot. Our team makes unique plans that match what each business wants to do. Whether it's a small new business, a local one, or a big company, we can help them use social media to get more customers without spending too much money.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>

    <section className="container-fluid pb-4">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Increase Website Traffic Through SMM
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             All companies can now reach a big number of audiences through social media. It's a genuine leveler of the marketing playing field, allowing small and mid-sized businesses to raise brand recognition, gain more followers, improve traffic, and increase conversions or qualified leads without having to invest a lot of money. Our social media marketing services in Canada will assist you in increasing the number of followers on social media and make sure that your regular clients' demographics, interests, and behaviors are reflected in your marketing campaigns.
             </p>
        </div>
        </div>
      </div>
    </section>


    <MiddleBannerPrimary
    Title="An Effective Strategy For Business Growth"
    Para="Your customers are more inclined to buy from you if you establish a deep relationship with them through involvement. Moreover, we'll show you how to get more people to participate with your social media postings, such as likes or comments. The greater your level of engagement is, the more successful you will be. Furthermore, having good website design and search engine optimization SEO is essential for business growth. In addition, getting new customers through email marketing is a great way to get them to start their business and take advantage of SMM."
    />

<section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-center gy-4'>
        <div className="col-11 col-md-5">
          <img className='w-100' src={Img1} alt="" />
        </div>
        <div className="col-11 col-md-5">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Tailored Solutions: Social Media Marketing Packages for Every Need
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             We have different plans to help businesses with social media. These plans are like <Link className='text-decoration-none text-ss-primary' to="/packages/"> special packages, </Link> each made to fit a business’s needs. It doesn’t matter if a company is starting or has been around for a long time. We can help them do better on social media. Our plans can make a business’s messages appear on social media so more people can see them. We can also create ads that tell people about the company and what it sells. And we check how well everything works to ensure it’s going well. <br/><br/>
             
             If a business wants to improve its, <Link className='text-decoration-none text-ss-primary' to="/services/ppc-marketing/"> online presence and get more customers, </Link> we have unique plans to help. Every business is different, and our plans can be changed to fit what each one wants to do. So, if you’re a business, pick the right plan for you, and we’ll help you do well on social media!             
             </p>
        </div>
        </div>
      </div>
    </section>

    <MiddleBannerSecondary
    Title="Social Media Analytics"
    Para={<>It's essential to establish up adequate tracking before you start promoting on social media. Our unique social media analytics give customers vital insights into the communities that surround their business. Our reports assist company owners in more precisely targeting their marketing message, from broad neighborhood demographics to engagement analytics that shows how specific effective campaigns are playing out.
    
    <div className='pt-4'>
    <Link to="/packages" className="text-decoration-none text-white fs-ss-18 fw-500 border-2 border-white border py-2 px-3"> View Packages <ChevronRight className='fs-ss-22'/> </Link>
    </div>
    </>}
    />
    
    <section className="container-fluid py-4 py-lg-5">
      <div className='row justify-content-evenly align-items-center'>
        <div className="col-11 col-md-10">
        <div className='mb-lg-4 mb-3'>
             <div className='fs-ss-36 text-philos fw-800 text-ss-black lh-sm'>
             Brand Management And Monitoring
             </div>
         </div>
         <div className=''>
             <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-8'>
             If your consumers are online, there’s a strong possibility they’re talking about you! Customers share their favorable and unfavorable impressions about firms on review sites and social media platforms. Furthermore, one of the most effective marketing methods you can do is to manage your online presence and reputation. If you have many social media channels but not strategies or proper techniques to be trending on SMM, there is no use in having many platforms but not beneficial. Negative comments and reviews can harm your search engine results and your potential to attract new customers. Email marketing is another effective way to grab the right audience and achieve your targeted goals. Moreover, paid marketing campaigns on different social media accounts will boost your revenue and sales. Because through pay-per-click, you can get your goals done quicker than your plan to. <br/><br/>
             
             Social media techniques are both creative and impactful to help you establish your brand, obtain visibility, and attract consumers. We assess your brand to decide the best method, voice to employ, content to develop, and audience to target. Moreover, our professional social media marketing services in Canada, are classified into different categories to help you create brand awareness, rank on the Google search bar, and have a solid social presence. We eliminate the fluff and deliver projects from idea to production. With hands-off execution plus outcomes you can see with essential services based around digital strategy, content creation, social brand management, site development, and viral promotion. In addition, creative production, go-to-market process, and user-accountability are some of our specialties that make us success driven.             </p>
        </div>
        </div>
      </div>
    </section>
    </section>
    </>
  )
}

export default SocialMediaMarketing
import { ArrowRight } from "react-bootstrap-icons"
import { Button } from "reactstrap"


const BrilliantMinds = () => {
    return (
        <div id='website-responsive'>
            <div className='py-md-4 py-4' style={{ backgroundColor: '#FE9B1F' }}>
                <div className="mt-md-4 px-sm-4 px-md-0">
                    <center>
                        <h4 className="fs-ss-48 text-philos fw-600 text-white" style={{ letterSpacing: '1px' }}>
                            We help brilliant minds to explore their way
                        </h4>
                    </center>
                </div>

                <div className="m">
                    <div className='mb-4 mb-md-4 mt-md-4 mt-4'>
                        <center>
                            <Button className='btn btn-ss-secondary-white ' style={{ borderRadius: '0px' }}>
                                <span className='fs-ss-18 text-mulish fw-500'>
                                    Contact Us
                                </span>
                                <ArrowRight className='ms-2' />
                            </Button>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrilliantMinds
import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const MainBanner = () => {
  return (
    <div id='website-responsive'  style={{ overflow:'hidden'}}>
        <div className='bg-img1' style={{ backgroundImage:'url("case-study/NetPlus-Case-Study-ZM-Banner-v1.1.jpg")', width:'100%' }}>
            <div className='ml-container' style={{ backgroundColor:'rgb(255, 165, 32, 0.6)' }}>
            <div className='d-grid justify-content-center p-lg-5 p-md-5 p-5'>
                <div className='pt-lg-5'>
                  <h2 className='fs-ss-82 text-lato text-white fw-700 lh-1'>
                        CASE STUDY
                  </h2>  
                </div>
                
                <div className='my-lg-4 my-4' style={{ borderTop:'5px solid white', width:'50%'}} />

                <div className='pb-lg-5 pt-lg-3 me-lg-4'>
                    <p className='fs-ss-20 text-mulish fw-500 text-white'>
                    ZM Communication helped NetPLUS with an efficient, multipurpose CRM that streamlined their B2C conversions and improved website development and lead management.
                    </p>
                </div>
                </div>
            </div>
        </div>


        <div className='display-none' style={{ padding:'', }} >
            <center>
                <div className='bg-ss-black rounde' style={{ boxShadow:'5px 3px 12px 0 rgba(0,0,0,.5)' }}>
                    <Row style={{ padding:'25px'}} className=''>
                        <Col md='4' lg='4' className='border-end border-white fs-ss-20 text-montserrat fw-700 text-white ' style={{ cursor:''}}>
                        <div className=''>
                            <div className='fs-ss-28 text-lato fw-600'> 
                                Client
                            </div>
                            <div className='fs-ss-20 text-lato fw-600'>
                                NetPLUS College
                            </div>
                        </div>
                        </Col>


                        <Col md='4' lg='4' className='border-end border-white fs-ss-20 text-montserrat fw-700 text-white' style={{ cursor:''}}> 
                        <div className=''>
                            <div className='fs-ss-28 text-lato fw-600'> 
                                Industry
                            </div>
                            <div className='fs-ss-20 text-lato fw-600'>
                                Education
                            </div>
                        </div></Col>

                        <Col md='4' lg='4' className='border-ed border-bl fs-ss-20 text-montserrat fw-700 text-white' style={{ cursor:''}}>
                        <div className='fs-ss-28 text-lato fw-600'>
                            Graduation Rate
                        </div>
                        <div className='fs-ss-20 text-lato fw-600'>
                            88.9%
                        </div>
                        </Col>
                    </Row>
                </div>
            </center>
        </div>
    </div>
  )
}

export default MainBanner
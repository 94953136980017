import React from 'react'
import { Col, Row } from 'reactstrap'

const OurApproach = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img left-padding10rem1 right-padding10rem1 py-md-5 py-4' style={{ backgroundImage:'url("home/Our-approach-bg.png")', width:'100%'}}>
            <div className='pb-md-5 pb-0' style={{ color:'#FE9B1F'}}>
                <center>
                    <h3 className='fs-ss-38 fw-800 text-philos text-ss-primary '>
                        Our Approach
                    </h3>
                </center>
            </div>

            <div className=''>
                <Row className=''>

                <Col md='4' className='px-5  display-block-767'>
                        <div className='px-sm-5 py-4'>
                            <img src='home/Our-approach.png' alt='' className='' width='100%'/>
                        </div>
                    </Col>

                    <Col md='4' className='mt-3 mt-md-0'>
                        <div className='' style={{ color:'#FE9B1F'}}>
                            <h3 className='fs-ss-30 fw-700 text-ss-primary text-philos' >
                                1. Envision
                            </h3>
                        </div>
                        <div className='fs-ss-16 text-mulish text-white fw-500 lh-1-2 '>
                        The sky just can’t be the limit; there is always the space beyond to discover and mysteries to unravel. We are determined to envision the future as an artistic undertaking that would enhance the growth of all businesses, taking them on the roller coaster of success; above and beyond.
                        </div>
                        <div className='mt-3' style={{ borderTop:'2px solid white', width:'65%'}} />
                    </Col>

                    <Col md='4' className='display-none-767'>
                        <div className=''>
                            <img src='home/Our-approach.png' alt='' className='' width='100%'/>
                        </div>
                    </Col>

                    <Col md='4' className='mt-3 mt-md-0'> 
                        <div className='' style={{ color:'#FE9B1F'}}>
                            <h3 className='fs-ss-30 fw-700 text-ss-primary text-philos' >
                                2. Execute
                            </h3>
                        </div>
                        <div className='fs-ss-16 text-mulish text-white fw-500 lh-1-2 '>
                        There is definitely no shortage of remarkable ideas here but what makes us different is the execution because we know that the implementation of any strategy is more important than the plan itself. That is why when it comes to your brand’s digital marketing growth, we plan with audacity and execute it with vigor.
                        </div>
                        <div className='mt-3' style={{ borderTop:'2px solid white', width:'65%'}} /></Col>

                    <Col md='4' className=''></Col> 

                    <Col md='4' className='ms-lg-5 ms-md-4 pt-md-4 pt-4'> 
                        <div className='' style={{ color:'#FE9B1F'}}>
                            <h3 className='fs-ss-30 fw-700 text-ss-primary text-philos' style={{ color:'#FE9B1F'}}>
                                3. Enrich
                            </h3>
                        </div>
                        <div className='fs-ss-16 text-mulish text-white fw-500 lh-1-2 '>
                        We have always subdued to our calling to enrich our clients with ideal battle strategies, beautiful content, and attractive visual details. We believe that without higher grounds, plains cannot be seen and the challenges help us to discover not our weaknesses but strengths. So, we are ready to enrich the future immeasurably. Are you?
                        </div>
                        <div className='mt-3' style={{ borderTop:'2px solid white', width:'65%'}} /></Col>

                    <Col md='4' className=''></Col> 

                </Row>
            </div>
        </div>
    </div>
  )
}

export default OurApproach
import React from 'react'
import { TypeAnimation } from 'react-type-animation'

const ServicesBanner = (props) => {
  return (
  <>
  <section id='website-responsive' className="container-fluid bg-banner pb-lg-5 pt-4" style={{backgroundImage:`url(${props.BannerImg})`}}>
    <div className='bg-overlay'>
    <div className="row justify-content-center py-lg-5 my-lg-5">
        <div className="col-11 col-lg-10 pb-5 mb-5">
        <div className=''>
            <div className='fs-ss-24 text-mulish text-white fw-500' style={{ letterSpacing:'1px'}}>
            {props.SubTitle}
            </div>
        </div>
        <div className=''>
                <TypeAnimation sequence={[`${props.Title}`,1000,'',200]}
                wrapper="span"
                speed={1}
                deletionSpeed={0}
                className='fs-ss-60 textss-primary fw-800 text-philos ms-1 ms-md4'
                style={{color:'#FE9B1F',  }}
                repeat={Infinity}
                cursor={false} />
        </div>
        </div>
    </div>
    </div>
  </section>
  </>
  )
}

export default ServicesBanner
import React from 'react'

const AboutMain = () => {
    return (
        <div id='website-responsive'>
            <div className='bg-img1 padding-about' style={{ backgroundImage: "url('about/Header-photos-12.jpg')", width: '100%', backgroundColor: 'rgba(0,0,0, 0.60)' }}>
                <h2 className='fs-ss-60 text-philos fw-800 text-white'>
                    Path To Your Success
                </h2>
            </div>
        </div>
    )
}

export default AboutMain
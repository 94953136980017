import React from 'react'
import { Col, Row } from 'reactstrap'

const CommitedToCare = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding8rem1 right-padding8rem1 pb-lg-5 pb-4'>
        <Row className=''>
            <Col lg='5' className=''>
            <div className='d-flex justify-content-center'>
                <img src='/healthcare/Health-Care-Image-1.jpg' alt=' ' className='img-w5' />
            </div>
            </Col>

            <Col lg='7' className=''>
            <div className='pt-lg-0 pt-4'>
                        <div className='mb-lg-3 mb-2'>
                            <h2 className='fs-ss-34 text-philos fw-800 text-ss-black'>
                                Committed to Care, Healing, and Recovery
                            </h2>
                        </div>

                        <div className='melg-5'>
                            <p className='fs-ss-16 text-mulish fw-500 text-ss-black lh-1-6'>
                            We deliver the correct tools to medical practitioners, surgeons, general physicians, dermatologists, cardiologists, clinics, and hospitals as a healthcare digital solution provider. We promote your services to a broad audience and assist you in better managing your healthcare data record -- safe and organized. Our team of competent and experienced IT professionals will listen to you and work with you to establish a strategy to help you build your brand, medical practice's digital application, website, and marketing needs. With various advantages of digital solutions for you as a digital healthcare solution provider and healthcare marketing firm, we ensure that you sustain and lead.
                            </p>
                        </div>
                    </div>
            </Col>
        </Row>
            

            
        </div>
    </div>
  )
}

export default CommitedToCare